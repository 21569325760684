<div class="container-fluid main-Cntn-Fluid">
  <div class="row main-Row">
    <div class="col-md-8 col-12 col-sm-12 col-lg-8 col-xl-8 main-Col-Span">
      <div class="container-fluid tabs-Section-Container">
        <div class="row tabs-Row-Flex">
          <div class="selected-Tab-Display-Div tab-Selec-Div col-lg-7 col-md-7 col-sm-7 col-xl-7 ">
            <span class="tab-Selected">{{ tabSelected }}</span>
          </div>
          <div class="multiple-Tab-Div large-Device-Tabs col-lg-5 col-xl-5 col-md-5 col-sm-5">
            <ul class="nav nav-tabs">
              <li
                class="nav-item p-0"
                [ngClass]="{ 'nav-Item-Border': tabSelected == 'Profil de l\'utilisateur' }"
              >
                <p
                  (click)="tabNavigation('Profil de l\'utilisateur', 'User')"
                  class="fontSize-Tab-Name"
                  [ngClass]="{ 'tab-Name': tabSelected == 'Profil de l\'utilisateur' }"
                >
                {{'profile.user'|cxTranslate}}
                </p>
              </li>
              <li
                class="nav-item"
                [ngClass]="{
                  'nav-Item-Border': tabSelected == 'Informations sur le compte établissement'
                }"
              >
                <p
                  class="fontSize-Tab-Name"
                  (click)="tabNavigation('Informations sur le compte établissement', 'Account')"
                  [ngClass]="{
                    'tab-Name': tabSelected == 'Informations sur le compte établissement'
                  }"
                >
                {{'profile.account'|cxTranslate}}
                </p>
              </li>
            </ul>
          </div>

          <div class="col-sm-12 col-12 col-xs-12 small-Device-Tabs">
            <ng-select
            class="small-Tab-Select"
            [items]="tabDataArray" bindLabel="label"
            bindValue="value" [multiple]="false" name="selecTabVal" [clearable]="false"
            placeholder=""
            (change)="tabNavigationDropDown()"
              [(ngModel)]="tabSelected">
            </ng-select>

          </div>
        </div>
      </div>
      <div class="tab-contents">
        <div id="User">
          <app-user></app-user>
        </div>
        <div id="Account">
          <app-account></app-account>
        </div>
        <div id="Payment"></div>
      </div>
    </div>
    <div class="col-md-4 col-12 col-sm-12 col-lg-4 col-xl-4 myAccount-Col-Span">
      <app-my-account></app-my-account>
    </div>
  </div>
</div>


