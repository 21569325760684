<div class="container">
  <div class="row">
    <div class="col-lg-3 col-xl-3 ">
      <span class="activeSeasonId">
        {{'selectVaccines.season'|cxTranslate}} {{activeSeasonId}}
      </span>
    </div>
    <div class="col-lg-5 col-xl-5 col-5 col-sm-5 col-md-7 bc-Dash-Div">
      <span class="back-To-Dashbaord" (click)="backToCategory()">
        <img src="assets/img/chevron-left.svg" class="svg-Images" alt="left" />
        {{'orderConfirmation.continueShopping'|cxTranslate}}</span>
    </div>
    <div class="col-lg-1 col-xl-1 col-3 col-sm-3 col-md-2 delte-Div">
      <a class="clear-cart clear-cart-img-section dlete-Cart" [ngbPopover]=popClearCartContent [popoverTitle]=popTitle
        triggers="manual" #p1="ngbPopover" placement="top" (click)="toggle(p1)" container="body"
        popoverClass="popover-links">
        <i class="fas fa-trash-alt pointer"></i>
        <span> {{'orderConfirmation.clearCart'|cxTranslate}}</span>
      </a>
    </div>
    <ng-template #popTitle><span class="text-header text-center">
        {{'addToCartItems.clearTitle'|cxTranslate}}</span>
    </ng-template>
    <ng-template #popClearCartContent>
      <button type="reset" class="popover-close btn
       white-button popover-cancel-button" data-di-id="di-id-d01008be-fb6e135c" (click)="toggle(p1)">
        {{'addToCartItems.cancel'|cxTranslate}}</button>
      <button class="btn blue-button popover-cancel-button" type="submit" data-di-id="di-id-3064f17d-e97cc628"
        (click)="deleteEntireCart(p1)">
        {{'orderConfirmation.clearCart'|cxTranslate}}</button>
    </ng-template>
    <div class="col-lg-1 col-xl-1  col-3 col-sm-3 col-md-1 print-Div">
      <span class="print" (click)="OnFluPrint()">
        <img src="assets/img/print.svg" class="svg-Images" alt="print" />
        {{'orderConfirmation.print'|cxTranslate}}</span>
    </div>
  </div>
</div>
<div *ngIf="deleteSuccess" class="global-alerts">

  <div class="alert delalert-info alert-dismissable getAccAlert">
    <button (click)="closeAlert()" class="close closeDelAlert" aria-hidden="true" data-dismiss="alert"
      type="button">×</button>
    <span [innerHTML]="deleteSuccess" class="delete-Sucess-Prod-Name"></span>
    {{'selectVaccines.deleteSuccessMsg'|cxTranslate}}.
  </div>
</div>

<div *ngIf="blockCheckout && showDangerAlert && fluProductsList?.length>0" class="global-alerts">

  <div class="alert alert-danger alert-dismissable getAccAlert">
    <button (click)="closeDangerAlert()" class="close closeAccAlert" aria-hidden="true" data-dismiss="alert"
      type="button" data-di-id="di-id-4f6d7b93-70563360">×</button>
    <div *ngIf="orderBlockNonHcp else NA">
      {{'selectVaccines.orderBlockMsgNonHcp'|cxTranslate}}
    </div>
    <ng-template #NA>
      {{'selectVaccines.orderBlockMsg'|cxTranslate}}
    </ng-template>
  </div>
</div>
<app-flu-header [selectedHeader]="header"></app-flu-header>

<section class="container influenzaCategory-Container">
  <section class="row">
    <!-- =====================estimation Section for IpAD(Potrait)======================= -->
    <section class="container est-Main-Container ipad-Container mobile-Container">
      <section class="row">
        <section class="col-md-12 col-lg-12 col-xl-12 text-center">
          <span class="est-Header" *ngIf="btnText!='Update Order' && !showEmptyCart">
            {{'orderDetail.orderSubtotal'|cxTranslate}}:
            {{estimatedSubtotal|currency}}</span>
          <span class="est-Header" *ngIf="btnText==='Update Order' && !showEmptyCart">
            Order Subtotal HT: € - EUR</span>
        </section>
        <section class="col-md-12 col-lg-12 col-xl-12">
          <button class="btn continue-Btn text-button-initial" (click)="navigateToCheckout()"
            *ngIf="btnText!='Update Order'" [ngClass]="{'block-Click':disableButton}"
            [disabled]="fluProductsList.length == 0 || blockCheckout">
            <i class="fa fa-spinner fa-spin" *ngIf="disableButton"></i>
            {{'selectVaccines.proceedToCheckout'|cxTranslate}}
          </button>
          <button class="btn edit-Btn text-button-initial" *ngIf="btnText==='Update Order'"
            (click)="navigateToCheckout()" [ngClass]="{'block-Click':disableButton}"
            [disabled]="fluProductsList.length == 0 || blockCheckout || wrongQuantity">
            <i class="fa fa-spinner fa-spin" *ngIf="disableButton"></i>
            {{'selectVaccines.updateOrder'|cxTranslate}}
          </button>
        </section>
        <section class="col-md-12 price-disclaimer">
          <p class="text-center">{{'orderConfirmation.priceDisclaimer' | cxTranslate}}</p>
        </section>
        <div class="col-md-12 text-center">
          <p>{{'orderDetail.taxDisclaimer' | cxTranslate }}</p>
        </div>
      </section>
    </section>
    <!-- =====================Left Section========================= -->
    <section class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 border-Right " *ngIf="!showEmptyCart">
      <!-- ===========================Shipping Address and Total Bill======================== -->
      <section class="row shipping-Bill-Main-Section">
        <section class="col-sm-6 col-md-6 col-lg-6 loc-left">
          <h6 class="Ship-to-location">{{'orderConfirmation.shiptoLoc'|cxTranslate}}</h6>
          <section class="ship-address">
            <section id="location-select">
              <section class="location-icon">
                <span class="glyph-icon">
                  <i class="fas fa-map-marker-alt icon mr-3"></i>
                </span>
              </section>
              <section class="location-Details">
                <span class="selected-loc">{{selectedData?.companyName}}</span>
                <span class="addr-label">
                  <p>
                    {{ selectedData?.line2 ? selectedData?.line2 : '' }}
                    {{ selectedData?.line1 ? selectedData?.line1 : '' }}
                  </p>
                  <p>
                    {{ selectedData?.postalCode ? selectedData?.postalCode : '' }}
                    {{ selectedData?.town ? selectedData?.town : '' }}
                  </p>

                  <div class="change-loc" role="button" *ngIf="changeLocation" (click)="open(content)">
                                    {{'account.changeLocation'|
                                    cxTranslate}}</div>
                </span>
              </section>
              <section class="custome-modal">
                <ng-template #content let-modal>
                  <section class="modal-header cart-Modal-Header">
                    <button type="button" class="close" aria-label="Close" (click)='onCancel()'
                      (click)="modal.dismiss('Cross click')">
                      <img alt="" src="../../assets/icons/close.png">
                    </button>
                    <h4 class="modal-title" id="modal-basic-title">{{'account.selectLocation'| cxTranslate}}</h4>
                  </section>
                  <section class="modal-body">

                    <section class="input-group loc-SearchBar">
                      <span class="input-group-btn">
                        <button class="btn" type="button">
                          <span class="loc-Search-Icon">

                          </span>
                        </button>
                        <input type="text" #clientName (keyup)="SearchClientName(clientName.value)"
                          class="form-control searchboxnew"
                          placeholder="{{'account.filterByLocation'| cxTranslate}}	" />
                      </span>
                    </section>
                    <hr [ngClass]="{'displayNone':searchedClientList?.length===0}" />
                    <section>
                      <section [ngClass]="{'displayNone':searchedClientList?.length===0}"
                        class="custom-control custom-radio radio_grp label-location w-100 m-0">
                        <table *ngFor="let data of searchedClientList;  let i = index" aria-describedby="locTable"
                          class="w-100">
                          <tr class="sample-header">
                            <th scope="col"></th>
                            <th scope="col"></th>
                          </tr>
                          <tr class="line-location" [ngClass]="{'label-location': (i !== searchedClientList.length-1)}">


                            <input type="radio" (change)='onOptionsSelected(data)' [id]="data.id"
                              [checked]="selectedData.id === data.id" name="customRadio" class="custom-control-input" />

                            <label class="custom-control-label secloct w-100" [for]="data.id">
                              {{data?.companyName}} {{"("+data?.unitID+")"}}
                            </label>
                            <p class="labelpp">
                              {{ data?.line2 ? data?.line2 : '' }}
                              {{ data?.line1 ? data?.line1 : '' }}
                            </p>
                            <p>
                              {{ data?.postalCode }}
                              {{ data?.town }}
                            </p>
                          </tr>
                        </table>
                      </section>
                    </section>
                  </section>
                  <section class="modal-footer">
                    <button type="button" class="btn location-done" (click)='onSubmit()'
                      (click)="modal.close('Save click')">
                      {{'account.done'| cxTranslate}}
                    </button>
                  </section>
                </ng-template>
              </section>
            </section>

          </section>
        </section>
        <!-- ============================================DATE PICKER=========================== -->
        <section class="col-sm-6 col-md-6 col-lg-6 datePickerSection hidden" *ngIf="showDatePicker">
          <section class="delivery-content multipleLocDelivery">
            <section class="delivary-details cart-delivery-date">
              <h6 class="Ship-to-location">{{'orderConfirmation.reqDeliveryDate'|cxTranslate}}</h6>
              <section class="calender">
                <section class="form-horizontal mb0">
                  <section class="control-group">
                    <section class="
                        controls
                        input-append
                        date
                        cart-delivery-date-picker
                      " data-date="" data-date-format="mm/dd/yyyy" data-link-field="dtp_input2"
                      data-link-format="mm/dd/yyyy">
                      <span class="add-on"><i class="icon-th"></i></span>

                      <input type="text" placeholder="DD/MM/YYYY" class="delivery-pickup-calendar" bsDatepicker
                        [(ngModel)]="deliveryDate" [daysDisabled]="[0,6]" [minDate]="minDeliveryDate"
                        [maxDate]="maxDeliveryDate" (ngModelChange)="onDeliveryDateChange($event)"
                        [ngClass]="{'required':dateIsRequired}"
                        [bsConfig]="{ containerClass: 'theme-green deliveryPickup' }" />
                    </section>

                    <p class="mx0 mb1">
                      {{'orderConfirmation.deliveryDisclaimer'|cxTranslate}}
                    </p>
                  </section>
                </section>
              </section>
            </section>
          </section>
        </section>

        <!-- ===========================DATE PIKER============================================= -->
      </section>
      <section class="row cart-Row" *ngFor="let product of fluProductsList; let i = index" id="{{ product.code }}">
        <!-- Image -->
        <section class="col-2 col-sm-1 col-md-1 col-lg-1 col-xl-1 prod-Image-Section">
          <section class="container">
            <section class="row">
              <section class="col-md-6 col-lg-6 col-xl-6">
                <a (click)="productDetails(product)">
                  <img *ngIf="product?.thumbNailImg !== ''; else undefinedImg" src="{{baseUrl}}{{product.thumbNailImg}}"
                    class="prod-Image" alt="{{product.name}}" />
                  <ng-template #undefinedImg>
                    <img [src]="fallbackImage" class="prod-Image" alt="{{product.name}}" />
                  </ng-template>
                </a>
              </section>
              <section class=" col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12
              delete-popover-container
              delete-Div">
                <i class="fas fa-trash-alt pointer"></i> &nbsp;
                <span [ngbPopover]="popContentDelete" [popoverTitle]="popTitleDelete" triggers="manual" #p2="ngbPopover"
                  placement="top" class="trash cart-edit-delete" (click)="toggle(p2)"
                  class="delete-Prod">{{'orderConfirmation.delete'|cxTranslate}}</span>

                <ng-template #popTitleDelete>
                  {{'addToCartItems.deleteTitle'|cxTranslate}}</ng-template>
                <ng-template #popContentDelete>
                  <button type="reset" class="popover-close btn white-button popover-cancel-button"
                    data-di-id="di-id-d01008be-fb6e135c" (click)="toggle(p2)">
                    {{'addToCartItems.cancel'|cxTranslate}}</button>
                  <button class="btn blue-button popover-cancel-button" type="submit"
                    data-di-id="di-id-3064f17d-e97cc628" (click)="deleteIndividualCartItem(product,p2)">
                    {{'addToCartItems.delete'|cxTranslate}}</button>
                </ng-template>


              </section>

            </section>
          </section>
        </section>
        <!-- Title -->
        <section class="col-10 col-sm-10 col-md-5 col-lg-5 col-xl-5 prod-Disc-Div">
          <section class="container">
            <section class="row">
              <section class="col-md-12 col-lg-12 col-xl-12">
                <h2 class="prodcut-Name" (click)="productDetails(product)" [innerHTML]="product.prodName">

                </h2>
              </section>
              <section class="col-md-12 col-lg-12 col-xl-12 product-Desc-Section" [innerHTML]="product.desc">
              </section>
              <section class="col-md-12 col-lg-12 col-xl-12 prod-No-Section" *ngIf="!hospital && product?.cipCode !== undefined">
                {{'selectVaccines.cipCode'|cxTranslate}}:
                <span class="prodct-No">{{product?.cipCode}}</span>
              </section>
              <section class="col-md-12 col-lg-12 col-xl-12 prod-No-Section" *ngIf="hospital && product?.ucdCode !== undefined">
                {{'selectVaccines.ucdCode'|cxTranslate}}:
                <span class="prodct-No">{{product?.ucdCode}}</span>
              </section>
            </section>
          </section>
        </section>
        <!-- Price -->
        <section class="col-8 col-sm-8 col-md-3 col-lg-3 col-xl-3 price-Main-Section">
          <section class="container">
            <section class="row">
              <!-- Catalog -->
              <section class="col-md-12 col-lg-12 col-xl-12 original-price">
                <span class="your-Price">{{'selectVaccines.catalogPrice'|cxTranslate}}</span>
                <br>
                <p class="product-Price">{{ product?.catalogPrice.value | localCurrency }}</p>
              </section>
              <!-- purchase price -->
              <section class="col-md-12 col-lg-12 col-xl-12">
                <span class="your-Price">{{"selectVaccines.purchasePrice" | cxTranslate}}</span>
                <br>
                <span class="product-Price">{{ product?.basePrice | localCurrency }}</span>
              </section>
            </section>
          </section>
        </section>
        <!-- Units -->
        <section class="col-4 col-sm-4 col-md-3 col-lg-3 col-xl-3 units-Main-Section">
          <section class="container pad-0">
            <!-- <section class="row"> -->
            <section class="prodUnits">
              <strong class="">{{'selectVaccines.doses'|cxTranslate}}</strong>
              <section class="units-area">
                <span class="disabled" aria-hidden="true" id="{{ 'minusIcon' + product?.code }}">
                  <img src="../../assets/icons/less.svg" id="{{ 'unitMinus' + product?.code }}"
                    [ngClass]="{'disabled-img':product?.totalQuantity <= product?.minQuantity}" alt="" (click)="
                      performQuantityComputation(
                        product,
                        2,
                        product?.code,
                        product?.stepQuantity,
                        product?.minQuantity,
                        product?.maxQuantity
                      )
                    " />
                  <img src="../../assets/icons/less-disable.svg"
                    [ngClass]="{'disabled-img':product?.minQuantity === undefined || product?.totalQuantity > product?.minQuantity}"
                    id="{{ 'unitMinusDisable' + product?.code }}" alt="" />
                </span>
                <input type="number" *ngIf="!product?.numberOfDosesPerUnit; else numberOfBoxesInput" class="product-quantity text-center" value="{{ product.totalQuantity }}"
                  onkeypress="return /[0-9a-zA-Z]/i.test(event.key)" (blur)="
                      performQuantityComputation(
                        product,
                        null,
                        product?.code,
                        product?.stepQuantity,
                        product?.minQuantity,
                        product?.maxQuantity
                      )
                    " id="{{ 'prodQuantity' + product?.code }}" min='${product.minQuantity}'
                  max='${product.minQuantity}' />
                  <ng-template #numberOfBoxesInput >
                    <input type="number" class="product-quantity text-center" [value]="product?.totalQuantity"
                    id="{{'prodQuantity'+ product?.code}}" min="1" max="9999"
                    [disabled]='true'
                    />
                  </ng-template>

                <span class="" aria-hidden="true" id="{{ 'plusIcon' + product?.code }}">
                  <img src="../../assets/icons/more.svg" id="{{ 'unitPlus' + product?.code }}" alt=""
                    [ngClass]="{'disabled-img':product?.totalQuantity >= product?.maxQuantity}" (click)="
                      performQuantityComputation(
                        product,
                        1,
                        product?.code,
                        product?.stepQuantity,
                        product?.minQuantity,
                        product?.maxQuantity
                      )
                    " />
                  <img src="../../assets/icons/more-disable.svg" id="{{ 'unitPlusDisable' + product?.code }}"
                    [ngClass]="{'disabled-img':product?.maxQuantity === undefined || product?.totalQuantity < product?.maxQuantity}"
                    alt="" />
                </span>
              </section>

              <section *ngIf="product?.numberOfDosesPerUnit" class="doses-section" id="{{'noOfDoses'+ product?.code}}">
                {{boxesQuantity}} {{'plp.boxes'|cxTranslate}}
              </section>

              <section class="col-md-12 col-lg-12 col-xl-12 p-0 "
                [ngClass]="{'frenchAvailable-Now':languageUsed==='fr', 'availableNow-Div' :languageUsed!='fr'}">

                <p class="available-now-text" *ngIf="product.onsn === 'ON'">
                  {{'selectVaccines.available_now'|cxTranslate}}
                </p>
                <span class="not-available-text" *ngIf="product.availableFlag === false && product.onsn !== 'ON'">
                  Product is not available
                </span>
                <span class="not-available-text" *ngIf="product.discontinuedFlag === true && product.onsn !== 'ON'">
                  Product is discontinued
                </span>
              </section>
            </section>
            <!-- </section> -->
          </section>
        </section>
      </section>
    </section>
    <!-- ==================NO DATA============================================== -->
    <section class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 border-Right-empty-Cart " *ngIf="showEmptyCart">
      <section class="container emptyCart-container">
        <section class="row">
          <h3 class="orderConfirmationHeader">{{'orderConfirmation.emptyCart'|cxTranslate}}</h3>
        </section>
        <section class="row reserve-link-row">
          <span class="empty-cart-links"
            (click)="backToDashBoard()">{{'orderConfirmation.reserveVaccines'|cxTranslate}}</span>
        </section>
      </section>
    </section>
    <!-- =====================Right Section========================= -->
    <section class="
        col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4
        right-Section-Top-Col-Span
      ">
      <!-- =====================estimation======================= -->
      <section class="container est-Main-Container normalDeviceConatiner">
        <section class="row">
          <section class="col-md-12 col-lg-12 col-xl-12 estHeader-ipad cart-Proceed-Section">
            <span class="est-Header" *ngIf=""> {{'orderDetail.orderSubtotal'|cxTranslate}}:
              {{estimatedSubtotal|localCurrency}}</span>
            <span class="est-Header" *ngIf="btnText!='Update Order' && !showEmptyCart">
              {{'orderDetail.orderSubtotal'|cxTranslate}}:
              {{estimatedSubtotal|localCurrency}}</span>
            <span class="est-Header" *ngIf="btnText==='Update Order' && !showEmptyCart">
              {{'orderDetail.orderSubtotal'|cxTranslate}}: {{"0"|localCurrency}}</span>
          </section>
          <section class="col-md-12 col-lg-12 col-xl-12 ">
            <button class="btn continue-Btn text-button-initial" (click)="navigateToCheckout()"
              *ngIf="btnText!='Update Order'" [ngClass]="{'block-Click':disableCheckoutButton}"
              [disabled]="fluProductsList.length == 0 || blockCheckout || minThresholdError">
              <i class="fa fa-spinner fa-spin" *ngIf="disableCheckoutButton"></i>
              {{'selectVaccines.proceedToCheckout'|cxTranslate}}
            </button>
            <button class="btn edit-Btn text-button-initial" *ngIf="btnText==='Update Order'"
              (click)="navigateToCheckout()" [ngClass]="{'block-Click':disableButton}"
              [disabled]="fluProductsList.length == 0 || blockCheckout || wrongQuantity || minThresholdError">
              <i class="fa fa-spinner fa-spin" *ngIf="disableButton"></i>
              {{'selectVaccines.updateOrder'|cxTranslate}}
            </button>
            <div *ngIf="minThresholdError" class="error-message">
              {{ 'selectVaccines.minThresholdError' | cxTranslate: {threshold: influenzaService.getInfluenzaThreshold()} }}
            </div>
          </section>
          <section class="col-md-12 price-disclaimer">
            <p class="text-center">{{'orderConfirmation.priceDisclaimer' | cxTranslate}}</p>
          </section>
          <div class="col-md-12 text-center">
            <p>{{'orderDetail.taxDisclaimer' | cxTranslate }}</p>
          </div>
        </section>
      </section>
      <section class="banner-Sect">
        <app-splitted-deliveries-paragraph></app-splitted-deliveries-paragraph>
      </section>
    </section>
  </section>
</section>
