<section class="row">
  <section class="container sub-Total-Section">
    <section class="row">
      <section class="col-8 col-sm-8 col-md-6 col-xl-6 col-lg-6">
        <span class="shipTo-Header">{{
          "addToCartItems.subtotal" | cxTranslate
          }}</span>
      </section>
      <section class="col-4 col-sm-4 col-md-6 col-xl-6 col-lg-6 text-right">
        <p>
          {{ abstractOrder?.totalPrice?.value | localCurrency }}
        </p>
      </section>
    </section>
  </section>
  <section class="container sub-Total-Section">
    <div class="row">
      <ng-container *ngFor="let tax of abstractOrder?.taxValues">
        <div class="col-8 col-sm-8 col-md-6 col-xl-8 col-lg-6">
          <p>
            {{ "checkout.tax" | cxTranslate}} ({{tax?.percentage}}%)
          </p>
        </div>
        <div class="col-4 col-sm-4 col-md-6 col-xl-4 col-lg-6 text-right">
          <p>
            {{tax?.value.value | localCurrency}}
          </p>
        </div>
      </ng-container>
    </div>
    <div class="row" *ngIf="abstractOrder?.deliveryCost != null && abstractOrder?.deliveryCost?.value != 0">
      <!-- Freight -->
      <div class="col-8 col-sm-8 col-md-6 col-xl-8 col-lg-6">
        <p>
          {{ "checkout.freight" | cxTranslate}}
        </p>
      </div>
      <div class="col-4 col-sm-4 col-md-6 col-xl-4 col-lg-6 text-right">
        <p>
          {{abstractOrder?.deliveryCost?.value | localCurrency}}
        </p>
      </div>
    </div>
    <div class="row total-Cost"><!--Total-->
      <div class="col-8 col-sm-8 col-md-6 col-xl-6 col-lg-6">
        <span class="shipTo-Header total-Amount-Span">
           {{"addToCartItems.TotalHt" | cxTranslate }}
        </span>
      </div>
      <div class="col-4 col-sm-4 col-md-6 col-xl-6 col-lg-6 text-right">
        <p>
          {{abstractOrder?.totalPrice?.value | localCurrency}}
        </p>
      </div>
    </div>
  </section>
</section>
