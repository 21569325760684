import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ModalDismissReasons, NgbModal, NgbPopover, NgbPopoverConfig } from '@ng-bootstrap/ng-bootstrap';
import {
  CmsService,
  GlobalMessageService,
  GlobalMessageType,
  OccEndpointsService,
  RoutingService,
  TranslationService,
} from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { enGbLocale } from 'ngx-bootstrap/locale';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { CustomProdPgService } from 'src/app/custom-prod-pg/custom-prod-pg.service';
import { MaximumQuantityInputError } from 'src/app/shared/errors/MaximumQuantityInputError';
import { MinimumQuantityInputError } from 'src/app/shared/errors/MinimumQuantityInputError';
import { QuantityInputError } from 'src/app/shared/errors/QuantityInputError';
import { BaseService } from 'src/app/shared/services/base.service';
import { DataLayerService } from 'src/app/shared/services/data-layer.service';
import { ProductUtils } from 'src/app/shared/utils/productUtils';
import { SubscriptionsContainer } from 'src/app/shared/utils/subscriptions-container';
import * as _ from 'underscore';

import { map, switchMap, tap } from 'rxjs/operators';
import { CartType } from 'src/app/shared/models/cart-type.model';
import { CustomAddToCartService } from '../../custom-add-to-cart/custom-add-to-cart.service';
import { ProductDetailsComponent } from '../../custom-add-to-cart/custom-add-to-cart/product-details/product-details.component';
import { IndirectService } from '../indirect.service';

@Component({
  selector: 'app-indirect-cart',
  templateUrl: './indirect-cart.component.html',
  styleUrls: ['./indirect-cart.component.scss'],
})
export class IndirectCartComponent implements OnInit, OnDestroy {
  @ViewChild('continueCheckout', { static: false })
  private readonly continueCheckout;
  deliveryPickup: string = 'delivery';
  isSticky: boolean = false;
  public productInfo: {
    available: boolean;
    availabilityText: string;
    batchInfo: {
      batchNumber: string;
      expiryDate: string;
      quantity: number;
    };
    images: any;
    code: string;
    description: string;
    discontinued: boolean;
    sapAllocation: boolean;
    displayProduct: boolean;
    flu: boolean;
    nameHtml: any;
    summary: any;
    productUpdate: string;
    productDocuments: {
      productDocument: [
        {
          url: string;
        }
      ];
    };
    name: string;
    orderable: boolean;
    quantityPerUnit: number;
    productUpdates: string;
    productDocument: any;
    volumePrices: [
      {
        currencyIso: string;
        formattedValue: string;
        maxQuantity: string;
        minQuantity: string;
        priceType: string;
        value: string;
      }
    ];
    url: string;
    stock: {
      isValueRounded: boolean;
    };
    isValueRounded: boolean;
    yellowFever: boolean;
    volumePricesFlag: boolean;
  } = null;
  standardCartDetails: any;
  productEntries: any = [];
  public images: any;
  updateTotal: boolean;
  updateEntriesData: any = [];
  subTotal: number;
  baseUrl: string;
  minDeliveryDate = new Date();
  maxDeliveryDate = new Date();
  minPickupDate = new Date();
  maxPickupDate = new Date();
  currentTime = new Date().getHours();
  pickupTimeFormat: any = [];
  timeFormat: any;
  selectedTime: any;
  deliveryDate: Date;
  deliveryCrossIcon: boolean;
  pickupDate: Date;
  deliveryDateFormat: string;
  pickupDateFormat: string;
  pickupCrossIcon: boolean;
  fallbackImage = '../../assets/i18n-assets/sanofi_missing_product.png';
  closeResult = '';
  usergetDataObj: any;
  getuserData: any;
  searchedClientList: any;
  selectedData: any;
  listSelectedData: any;
  userAccountgetData: any;
  saveCartName: string;
  popoverConfig: NgbPopoverConfig;
  cartId: any;
  errorMsg: string;
  deleteSuccess: string;
  addSuccess = false;
  cartItems: any;
  changeLocation = false;
  templatePage: any;
  prodAddedSub: Subscription;
  cartItemsSub: Subscription;
  removeEntriesSub: Subscription;
  deleteCartSub: Subscription;
  cartDetailsSub: Subscription;
  checkoutDetailsSub: Subscription;
  checkoutFailedSub: Subscription;
  userAccountDetailsSub: Subscription;
  changedLocSub: Subscription;
  saveCartInvalidSub: Subscription;
  saveCartSuccessSub: Subscription;
  plpMiniCartSub: Subscription;
  userDataSub: Subscription;
  @ViewChild('p2') public savePopover: NgbPopover;
  orderBlock = false;
  orderBlockNonHcp = true;
  showDangerAlert = false;
  checkoutClicked = false;
  yfEntry = false;
  subs = new SubscriptionsContainer();
  wrongQuantity: boolean = false;
  cartDetailsRan: boolean = false;
  productLimits: any = [];
  check: boolean;
  public hasValidated = new BehaviorSubject<any>(false);
  hasValidated$ = this.hasValidated.asObservable();
  showDisclaimer: boolean = true;

  allQuestionnairsSub: Subscription;
  public questionnaireType = new BehaviorSubject<any[]>([]);
  questionnaireType$ = this.questionnaireType.asObservable();
  public allPraluentType = new BehaviorSubject<any[]>([]);
  allPraluentType$ = this.allPraluentType.asObservable();
  public allDupixientType = new BehaviorSubject<any[]>([]);
  allDupixientType$ = this.allDupixientType.asObservable();
  shuffleAllDupixientType$ = this.allDupixientType.asObservable();
  shuffleAllPraluentType$ = this.allDupixientType.asObservable();
  userId: any;

  PROCESS_TYPE = 'standard process';

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly addToCart: CustomAddToCartService,
    readonly user: UserAccountFacade,
    private readonly modalService: NgbModal,
    private localeService: BsLocaleService,
    public datepipe: DatePipe,
    private readonly config: NgbPopoverConfig,
    private readonly route: RoutingService,
    private readonly occEndPoint: OccEndpointsService,
    private readonly plpService: CustomProdPgService,
    private globalMessage: GlobalMessageService,
    private readonly pageDate: CmsService,
    private dataLayerService: DataLayerService,
    private readonly translation: TranslationService,
    private readonly baseService: BaseService,
    private readonly indirectService: IndirectService
  ) {
    this.images = {};
    this.updateTotal = false;
    enGbLocale.weekdaysShort = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
    enGbLocale.week.dow = 0;
    defineLocale('en-gb', enGbLocale);
    this.localeService.use('en-gb');
    this.pickupTimeFormat = [
      '09:00 am',
      '10:00 am',
      '11:00 am',
      '12:00 pm',
      '01:00 pm',
      '02:00 pm',
      '03:00 pm',
      '04:00 pm',
      '05:00 pm',
    ];
    this.selectedTime = null;
    config.autoClose = 'outside';
    this.deleteSuccess = '';
    this.addSuccess = false;
    this.check = true;
  }

  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isSticky = window.pageYOffset >= 400;
  }
  //Important for mapping
  questionsPraluent = [
    { text: 'Quel est le type d’accord préalable pour cette prescription ?', answerType: 'preliminaryAgreement' },
    { text: 'Quel est le type de prescription ?', answerType: 'prescriptionType'  },
    { text: 'Quelle est la spécialité du prescripteur ?', answerType: 'prescriberSpecialty' },
  ];
  questionsDupixent = [
    { text: 'Quelle est la spécialité du prescripteur ?', answerType: 'prescriberSpecialty' },
    { text: 'Quelle est la pathologie du patient ?', answerType: 'patientPathology' },
    { text: 'Quel est le type de prescription ?', answerType: 'prescriptionType'  },
  ];

  selectedDupixentAnswers = {
    prescriptionType: "",
    patientPathology: "",
    prescriberSpecialty: "",
    prescriberZipCode: ""
  };
  selectedPraluentAnswers = {
    preliminaryAgreement: "",
    prescriptionType: "",
    prescriberSpecialty: "",
    prescriberZipCode: ""
  };
  zipCodePraluent:number = null;
  onZipCodePraluent(value):void{
    this.selectedPraluentAnswers['prescriberZipCode'] = value?.toString();
    this.updateSubmitButtonState();
  }
  zipCodeDupixent: number = null;
  onZipCodeDupixent(value):void{
    this.selectedDupixentAnswers['prescriberZipCode'] = value?.toString();
    this.updateSubmitButtonState();
  }
  selectedRadioPraluent: string = '';
  selectedRadioDupixient: string = ''
  allAnswersDupixentSelected: boolean = false;
  allAnswersPraluentSelected: boolean = false;

  onOptionSelect(name, option, qType):void{
    if(qType === "PRALUENT"){
      this.selectedPraluentAnswers[name] = option
    }
    else if(qType === "DUPIXENT"){
      this.selectedDupixentAnswers[name] = option
    }
    this.updateSubmitButtonState();

  }
  submitDisabled: boolean = true;
  submitDisabledPraluent: boolean = true;
  submitDisabledDupixent: boolean = true;
  
  updateSubmitButtonState() {
    let allRadioAns = false;
    let allRadioAnsPraluent = false;
    let allRadioAnsDupixent = false;
    let dupRadioLength = 0;
    let praRadioLength = 0
   Object.keys(this.selectedDupixentAnswers).map((key) =>{
    if(this.selectedDupixentAnswers[key] !== ""){
      dupRadioLength++;
    }
   })
   Object.keys(this.selectedPraluentAnswers).map((key) =>{
    if(this.selectedPraluentAnswers[key] !== ""){
      praRadioLength++;
    }
   })


  allRadioAns = dupRadioLength === 4 && praRadioLength === 4 ? true : false;
  
  allRadioAnsPraluent = praRadioLength === 4 ? true : false;
  allRadioAnsDupixent = dupRadioLength === 4 ? true : false;
   
   this.indirectService.setLocalData("selectedAnswers", allRadioAns )
    this.submitDisabled = !(allRadioAns && (!!this.zipCodePraluent && this.zipCodePraluent.toString().length === 5) && (!!this.zipCodeDupixent && this.zipCodeDupixent.toString().length === 5 ));
    this.submitDisabledPraluent = !(allRadioAnsPraluent && (!!this.zipCodePraluent && this.zipCodePraluent.toString().length === 5));
    this.submitDisabledDupixent = !(allRadioAnsDupixent && (!!this.zipCodeDupixent && this.zipCodeDupixent.toString().length === 5 ));
  }

  ngOnInit(): void {
    const shuffleKeepingJeNeSaisPasAtEnd = (parentArray: any[][]): any[][] => {
      return parentArray.map(childArray => {
        const fixedItems = childArray.filter(item => item.name.startsWith("Je ne sais pas"));
        const shuffleItems = childArray.filter(item => !item.name.startsWith("Je ne sais pas"));
        shuffleItems.forEach((_, i) => {
          const j = Math.floor(Math.random() * (i + 1));
          [shuffleItems[i], shuffleItems[j]] = [shuffleItems[j], shuffleItems[i]];
        });
        return [...shuffleItems, ...fixedItems];
      });
    };
    this.shuffleAllDupixientType$ = this.allDupixientType$.pipe(map(shuffleKeepingJeNeSaisPasAtEnd));
    this.shuffleAllPraluentType$ = this.allPraluentType$.pipe(map(shuffleKeepingJeNeSaisPasAtEnd));
    
    this.globalMessage.remove(GlobalMessageType.MSG_TYPE_ERROR);
    this.plpMiniCartSub = this.plpService
      .getUpdateMiniCart()
      .subscribe((res) => {
        if (res) {
          this.addToCart.removedEntries(res, this.userAccountgetData?.userName);
          this.removeEntriesMapping();
          this.plpMiniCartSub?.unsubscribe();
        }
      });
    this.plpService.stdCartMsg.next(false);
    this.baseUrl = this.occEndPoint.getBaseUrl({
      baseUrl: true,
      prefix: false,
      baseSite: false,
    });
    this.subs.add = this.pageDate.getCurrentPage().subscribe((res) => {
      if (res) {
        this.templatePage = res?.template;
        this.subs.dispose();
      }
    });
    this.userDataSub = this.user.get().subscribe((res) => {
      if (res) {
        this.userId = res.uid
        this.userAccountgetData = res;
        if (this.userAccountgetData?.akamaiId) {
          this.orderBlockNonHcp = false;
        }
        this.getUserAccountData(this.userAccountgetData.userName);
        this.productEntries = [];
        this.getCartDetails();
        const isBeyfortusType = (currentValue) => currentValue.product.productType === 'BEYFORTUS';
        this.orderBlock = this.orderBlock && !this.productEntries.every(isBeyfortusType);
        this.cdr.detectChanges();
        this.userDataSub?.unsubscribe();
      }
    });
    this.prodAddedSub = this.plpService.stdCartMsg$.subscribe((res) => {
      if (res) {
        this.addSuccess = true;
        this.deleteSuccess = '';
        this.plpService.stdCartMsg.next(false);
        this.prodAddedSub.unsubscribe();
      }
    });
    this.setDeliveryDate();
    this.setPickupDate();
  }

  getCartDetails() {
    this.cartItemsSub = this.addToCart.cartItems$.subscribe((cartData: any) => {
      if (cartData) {
        this.cartDataMapping(cartData);
      }
    });
    if (this.cartId && this.templatePage === 'PageTemplate') {
      this.addToCart.removedEntries(
        this.cartId,
        this.userAccountgetData?.userName
      );
      this.removeEntriesMapping();
    }
  }

  removeEntriesMapping() {
    this.removeEntriesSub = this.addToCart.removedItems$.subscribe(
      (res: any) => {
        if (res) {
          const removedData = res?.removedEntries?.cartModifications;
          let stdCartDetails;
          this.yfEntry = false;
          stdCartDetails = res?.childCarts;
          if (stdCartDetails) {
            stdCartDetails = stdCartDetails.filter(
              (item) => item.cartType === 'INDIRECT'
            );
            if (stdCartDetails && stdCartDetails?.length > 0) {
              stdCartDetails = Object.assign({}, ...stdCartDetails);
              for (const entries of stdCartDetails?.entries) {
                if (entries?.product?.yellowFever) {
                  this.yfEntry = true;
                }
              }
            }
          }
          if (!stdCartDetails?.shipToParty?.yellowFeverFlag && this.yfEntry) {
            this.yfEntry = true;
          } else {
            this.yfEntry = false;
            this.globalMessage.remove(GlobalMessageType.MSG_TYPE_ERROR);
          }
          if (removedData && removedData?.length > 0) {
            for (const data of removedData) {
              if (
                !stdCartDetails?.shipToParty?.yellowFeverFlag &&
                this.yfEntry
              ) {
                this.globalMessage.add(
                  data.statusMessage,
                  GlobalMessageType.MSG_TYPE_ERROR,
                  5000000
                );
              } else {
                this.globalMessage.add(
                  data.statusMessage,
                  GlobalMessageType.MSG_TYPE_ERROR
                );
              }
            }
          }
          this.cdr.detectChanges();
          this.addToCart.getAllCarts(this.userAccountgetData?.userName);
          this.addToCart.removedItems.next(false);
        }
        this.cdr.detectChanges();
      }
    );
  }

  cartDataMapping(data): void {
    let stdCartDetails = [];
    this.productEntries = [];
    this.cartId = data?.code;
    stdCartDetails = data?.childCarts;
    if (stdCartDetails) {
      stdCartDetails = stdCartDetails.filter(
        (item) => item.cartType === 'INDIRECT'
      );

      if (!this.cartDetailsRan) {
        //if that thing hasn't happened yet and the mouse is pressed
        this.cartDetailsRan = true;
        this.dataLayerService.viewCartDataLayer(
          stdCartDetails,
          this.PROCESS_TYPE
        );
      }

      this.standardCartDetails = Object.assign({}, ...stdCartDetails);
      for (const cartDetails of stdCartDetails) {
        for (let entries of cartDetails.entries) {
          const imagesFormat = _.clone(
            _.findWhere(entries.product?.images, {format: 'thumbnail'})
          );
          if (imagesFormat) {
            imagesFormat.url = this.baseUrl + imagesFormat?.url;
          }
          this.images = {PRIMARY: imagesFormat};
          entries = {...entries, ...this.images};
          this.productEntries.push(entries);
        }
      }

      const prodCodes = [];
      this.productEntries.forEach((element) => {
        prodCodes.push(element.product.code);
      });
      if (prodCodes.length >= 1) {
        this.getVolumePrice(prodCodes);
      }

      if (this.standardCartDetails?.deliveryAddress) {
        this.selectedData = this.standardCartDetails?.deliveryAddress;
      } else {
        if (this.getuserData && this.getuserData?.length > 0) {
          this.selectedData = _.first(
            this.getuserData.filter(
              (item) => item.unitID === this.userAccountgetData?.uid
            )
          );
        }
      }
      this.saveCartName = this.selectedData?.companyName;

      this.cdr.detectChanges();
    }
  }

  private getVolumePrice(prodCodes) {
    this.user.get().subscribe((customer: any) => {
      if (customer) {
        const params = {
          productCodes: prodCodes,
        };
        this.baseService
          .post(
            `/products/getPrice?fields=DEFAULT&emulatedUser=${customer.userName}`,
            params
          )
          .subscribe((volPrices) => {
            if (volPrices) {
              this.productEntries.forEach((prodEntry) => {
                const code = prodEntry.product.code;
                volPrices.productPrices.forEach((volPrice) => {
                  if (volPrice.code === code) {
                    prodEntry.product.volumePrices = volPrice?.volumePrices;
                  }
                });
              });
              this.cdr.detectChanges();
            }
          });
      }
    });
  }

  private getProductLimits(): Observable<any> {
    this.productLimits = [];
    const productObjects = [];
    return this.user.get().pipe(switchMap((customer: any) => {
      if (customer) {
        this.productEntries.forEach((entry) => {
          const entryObject = {
            code: entry.product.code,
            requestedQuantity: entry.quantity,
            name: entry.product.name,
          };
          productObjects.push(entryObject);

        });
        const params = {
          productLimits: productObjects,
        }
        return this.baseService
          .post(
            `/products/getProductLimit?fields=DEFAULT&emulatedUser=${customer.userName}`,
            params
          );
      }
    }));

  }

  performQuantityComputation(
    product,
    operator,
    idData,
    stepQuantity,
    minQuantity,
    maxQuantity,
    entryNumber
  ): void {
    this.globalMessage.remove(GlobalMessageType.MSG_TYPE_ERROR);
    this.wrongQuantity = false;

    try {
      ProductUtils.alterQuantity(
        operator,
        idData,
        stepQuantity,
        minQuantity,
        maxQuantity,
        'cart'
      );
    } catch (e: any) {
      if (e instanceof QuantityInputError) {
        this.wrongQuantity = true;
        this.translation
          .translate('addToCartItems.inputStepQuantityErrorMsg')
          .subscribe((res) => {
            this.globalMessage.add(
              product.name + ':' + res,
              GlobalMessageType.MSG_TYPE_ERROR
            );
          });
      } else if (e instanceof MinimumQuantityInputError) {
        this.wrongQuantity = true;
        this.translation
          .translate('addToCartItems.inputMinimumQuantityErrorMsg')
          .subscribe((res) => {
            this.globalMessage.add(
              product.name + ':' + res,
              GlobalMessageType.MSG_TYPE_ERROR
            );
          });
      } else if (e instanceof MaximumQuantityInputError) {
        this.wrongQuantity = true;
        this.translation
          .translate('addToCartItems.inputMaximumQuantityErrorMsg')
          .subscribe((res) => {
            this.globalMessage.add(
              product.name + ':' + res,
              GlobalMessageType.MSG_TYPE_ERROR
            );
          });
      }
    }
    this.updateTotal = true;
    const inputElement = document.getElementById(
      `prodQuantity${idData}`
    ) as HTMLInputElement;
    const params = {entryNumber: entryNumber, quantity: inputElement.value};
    if (
      this.updateEntriesData.some(
        (entry) => entry.entryNumber === params.entryNumber
      )
    ) {
      this.updateEntriesData[
        this.updateEntriesData.findIndex(
          (el) => el.entryNumber === params.entryNumber
        )
        ] = params;
    } else {
      this.updateEntriesData = [...this.updateEntriesData, params];
    }
  }

  deleteCart(entryNumber, prodName): void {
    this.addToCart.deleteCartItem(
      entryNumber,
      this.standardCartDetails.code,
      this.cartId
    );
    this.deleteCartSub = this.addToCart.deleteItems$.subscribe((res) => {
      if (res) {
        this.addToCart.deleteItems.next(false);
        this.addToCart.removedEntries(
          this.cartId,
          this.userAccountgetData?.userName
        );
        this.removeEntriesMapping();
        this.deleteSuccess = prodName;
        this.addSuccess = false;
      }
    });
  }

  emptyCart(): void {
    this.clearChildCart()
  }

  getArrayLength(): number {
    return this.productEntries.length;
  }

  closeAlert() {
    this.deleteSuccess = '';
    this.addSuccess = false;
  }

  closeDangerAlert() {
    this.showDangerAlert = false;
  }

  clearChildCart(): void {
    this.addToCart.clearCart(this.standardCartDetails.code);
  }

  openPopover(popover) {
    popover.open();
  }

  toggle(popover) {
    if (popover.isOpen()) {
      popover.close();
    } else {
      popover.open();
    }
    this.errorMsg = '';
    this.saveCartName = this.selectedData?.companyName;
  }

  getQuestionnairs(): void {
    this.indirectService.getQuestionnairsTypes(
      this.cartId,
      this.userId
    );

    this.indirectService.allQuestionnairs$.subscribe((res: any) =>{

      if(res){
        this.questionnaireType.next(res)
        this.indirectService.allDupixientAnswers$.subscribe((data)=>{
          this.allDupixientType.next(data)
        })
        this.indirectService.allPraluentAnswers$.subscribe((data)=>{
          this.allPraluentType.next(data)
        })
      }
    })
  }

  updateCart(): void {
    this.indirectService.updateCartDetails(
      this.standardCartDetails.code,
      this.updateEntriesData,
      this.cartId,
    );
    this.cartDetailsSub = this.addToCart.cartDetails$.subscribe((res: any) => {
      if (res) {
        this.updateTotal = false;
        let stdCartDetails = [];
        stdCartDetails = res?.childCarts;
        stdCartDetails = stdCartDetails.filter(
          (item) => item.cartType === 'INDIRECT'
        );
        this.dataLayerService.addUpdateCartDataLayer(
          stdCartDetails,
          this.PROCESS_TYPE,
          null,
          'cart'
        );
        this.standardCartDetails = Object.assign({}, ...stdCartDetails);
        this.dataLayerService.viewCartUpdateQuantityDataLayer(
          this.standardCartDetails,
          this.PROCESS_TYPE
        );
      }
      this.cdr.detectChanges();
    });
  }

  productDetails(productDetails): void {
    const modalRef = this.modalService.open(ProductDetailsComponent, {
      windowClass: 'product-details-modal',
    });
    modalRef.componentInstance.productDetails = productDetails;
    modalRef.componentInstance.standardCartCode = this.standardCartDetails.code;
    modalRef.componentInstance.unitSection = 'Update';
  }

  clearDate(dpDate): void {
    if (dpDate === 'Delivery') {
      this.deliveryDate = null;
      this.deliveryCrossIcon = false;
    } else {
      this.pickupDate = null;
      this.pickupCrossIcon = false;
      this.selectedTime = null;
    }
  }

  proceedToCheckoutOne(): void {
    this.getQuestionnairs();
    this.zipCodeDupixent = null;
    this.zipCodePraluent = null;
    this.submitDisabled = true;
    this.modalService.open(this.continueCheckout);
  }

  closeQuestionaireModal(): void{
    this.zipCodeDupixent = null;
    this.zipCodePraluent = null;
    this.submitDisabled = true;
    this.modalService.dismissAll();
    this.submitDisabledPraluent = true;
    this.submitDisabledPraluent = true;
    window.location.reload()
  }
  submitQuestionaire():void{
    const questionnaireAnswers = {
        dupixent: this.selectedDupixentAnswers,
        praluent: this.selectedPraluentAnswers
    }
    this.indirectService.setLocalData("questionnaire", questionnaireAnswers)
    
    this.proceedToCheckout();
    this.validateQuantity();
  }
  proceedToCheckout(): void {
    this.modalService.dismissAll();
    this.check = true;
    this.validateQuantity();
    this.hasValidated$.subscribe((res: any) => {
      if (res &&
        this.check &&
        (this.deliveryDateFormat || (this.pickupDateFormat && this.selectedTime))
      ) {

        const params = {
          customerId: this.usergetDataObj.uid,
          date:
            this.deliveryPickup === 'delivery' &&
            (this.deliveryDateFormat || this.pickupDateFormat)
              ? this.deliveryDateFormat
              : this.pickupDateFormat,
          deliveryMode:
            this.deliveryPickup === 'delivery' ? 'standard' : 'pickup',
          pickupTime:
            this.deliveryPickup === 'pickup' && this.selectedTime
              ? this.selectedTime
              : '',
          shipToUnitId: this.selectedData?.unitID,
          standard: true,
          cartType: CartType.INDIRECT
        };
        this.addToCart.checkoutCart(params, this.cartId);
        this.checkoutClicked = true;

        this.checkoutDetailsSub = this.addToCart.checkoutDetails$.subscribe(
          (resp) => {
            if (resp) {
              this.checkoutClicked = false;
              this.route.goByUrl('indirect/checkout');
            }
          }
        );

        this.checkoutFailedSub = this.addToCart.checkoutFailed$.subscribe(
          (resp) => {
            if (resp) {
              this.checkoutClicked = false;
            }
          }
        );
      }
    });
  }



  validateQuantity() {
    const productCodes = [];
    this.productEntries.forEach((element, index) => {
      productCodes.push(element.product?.code);
      if (element.quantity < element.product?.minQuantity) {
        this.check = false;
        this.translation
          .translate('addToCartItems.inputMinimumQuantityErrorMsg')
          .subscribe((res) => {
            this.globalMessage.add(
              element.product?.name + ':' + res,
              GlobalMessageType.MSG_TYPE_ERROR
            );
          });
      } else if (element.quantity > element.product?.maxQuantity) {
        this.check = false;
        this.translation
          .translate('addToCartItems.inputMaximumQuantityErrorMsg')
          .subscribe((res) => {
            this.globalMessage.add(
              element.product?.name + ':' + res,
              GlobalMessageType.MSG_TYPE_ERROR
            );
          });
      } else if (element.quantity % element.product?.stepQuantity !== 0) {
        this.check = false;
        this.translation
          .translate('addToCartItems.inputStepQuantityErrorMsg')
          .subscribe((res) => {
            this.globalMessage.add(
              element.product?.name + ':' + res,
              GlobalMessageType.MSG_TYPE_ERROR
            );
          });
      } else {
        this.hasValidated.next(true);
      }
    });
  }

  validateLimitQuantity(productCodes) {
    this.hasValidated.next(false);
    this.getProductLimits().pipe(tap((res) => {
      res.productLimits.forEach((limit) => {
        const limitObj = {
          code: limit.code,
          quantity: limit.exceededQuantity,
          name: limit.name,
        };
        this.productLimits.push(limitObj);
      });
      this.productLimits.forEach((limit) => {
        if (limit?.quantity > 0) {
          this.check = false;
          this.translation
            .translate('addToCartItems.inputQuantityLimitErrorMsg')
            .subscribe((resp) => {
              this.globalMessage.add(
                resp + limit.name,
                GlobalMessageType.MSG_TYPE_ERROR
              );
            });
        }
      });
      this.hasValidated.next(true);
    })).subscribe();
  }

  open(content): void {
    this.modalService
      .open(content, {ariaLabelledBy: 'modal-basic-title'})
      .result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  getUserAccountData(userId): void {
    this.userAccountDetailsSub = this.addToCart.userAccountDetails$.subscribe(
      (userDataget) => {
        if (userDataget) {
          this.usergetDataObj = userDataget.res;
          this.getuserData = userDataget.res.shippingAddresses;
          this.searchedClientList = userDataget.res.shippingAddresses;
          this.orderBlock = userDataget?.res?.orderOnsnBlockFlag ? true : false;
          if (this.orderBlock) {
            this.showDangerAlert = true;
          }
          this.changeLocation = this.getuserData?.length <= 1 ? false : true;
          this.cdr.detectChanges();
        }
      }
    );
  }

  onOptionsSelected(data) {
    this.listSelectedData = data;
  }

  onSubmit(): void {
    if (this.listSelectedData) {
      this.selectedData = this.listSelectedData;
      this.addToCart.saveLocation(
        this.selectedData?.unitID,
        this.cartId,
        this.userAccountgetData?.userName
      );
      this.changedLocSub = this.addToCart.changedLocation$.subscribe((res) => {
        if (res) {
          this.addToCart.removedEntries(
            this.cartId,
            this.userAccountgetData?.userName
          );
          this.removeEntriesMapping();
        }
      });
    }
  }

  SearchClientName(data: any) {
    if (data.length > 0) {
      this.searchedClientList = this.getuserData.filter(
        (x) =>
          (x.companyName?.toLowerCase().indexOf(data.toLowerCase()) >= 0) ||
          (x.unitID?.toLowerCase().indexOf(data.toLowerCase()) >= 0) ||
          (x.line1?.toLowerCase().indexOf(data.toLowerCase()) >= 0) ||
          (x.line2?.toLowerCase().indexOf(data.toLowerCase()) >= 0) ||
          (x.town?.toLowerCase().indexOf(data.toLowerCase()) >= 0) ||
          (x.postalCode?.toLowerCase().indexOf(data.toLowerCase()) >= 0)
      );
    } else {
      this.searchedClientList = this.getuserData;
    }
  }

  saveOrder(): void {
    const reg = /^[^`!@#~$%\^&*()_+\-={};':"\\|[\]\\.,<>/\/?~]*$/;
    if (this.saveCartName && RegExp(reg).test(this.saveCartName)) {
      this.addToCart.saveCartDetails(this.saveCartName, this.cartId);
      this.saveCartInvalidSub = this.addToCart.invalidCartName$.subscribe(
        (res) => {
          if (res) {
            for (const error of res?.error?.errors) {
              this.errorMsg = error?.message;
            }
          }
        }
      );
      this.saveCartSuccessSub = this.addToCart.saveCartSuccess$.subscribe(
        (res) => {
          if (res) {
            if (this.savePopover) {
              this.savePopover.close();
            }
            this.errorMsg = '';
            this.saveCartName = this.selectedData?.companyName;
          }
        }
      );
    } else {
      if (this.saveCartName) {
        this.errorMsg = 'Save cart name is not valid.';
      } else {
        this.errorMsg = 'Cart name cannot be blank';
      }
    }
  }

  exportXls() {
    const cartProducts = [];
    for (const entries of this.productEntries) {
      cartProducts.push({
        'Customer Name': this.standardCartDetails?.orderContact?.name,
        Date: new Date().toLocaleDateString(),
        'Product Name': entries?.product?.name,
        'Product CIP Code': entries?.product?.cipCode,
        Quantity: entries?.quantity,
        'Delivery Address':
          this.selectedData?.companyName +
          ' (' +
          this.selectedData?.unitID +
          ') ' +
          (this.selectedData?.line1 ? this.selectedData?.line1 + ', ' : '') +
          (this.selectedData?.line2 ? this.selectedData?.line2 + ', ' : '') +
          (this.selectedData?.town ? this.selectedData?.town + ', ' : '') +
          (this.selectedData?.region?.isocodeShort
            ? this.selectedData?.region?.isocodeShort + ' '
            : '') +
          (this.selectedData?.postalCode ?? ''),
        'Unit Price':
          entries?.basePrice?.value + ' ' + entries?.basePrice?.currencyIso,
        Subtotal:
          entries?.totalPrice?.value + ' ' + entries?.totalPrice?.currencyIso,
      });
    }
    const excelDataLayer = {
      localizedDownloadedMedia: {
        name: 'cart.xlsx',
      },
      downloadedMediaType: 'xlsx',
    };
    this.dataLayerService.downloadDataLayer(excelDataLayer);
    this.addToCart.downloadXls(cartProducts, 'Cart');
  }

  omit_special_char(event) {
    this.errorMsg = '';
    const reg = /^[^`!@#~$%\^&*()_+\-={};':"\\|[\]\\.,<>/\/?~]*$/;
    return new RegExp(reg).test(event.key);
  }

  setDeliveryDate() {
    this.minDeliveryDate.setDate(this.minDeliveryDate.getDate() + 2);
    if (
      this.minDeliveryDate.getDay() === 0 ||
      this.minDeliveryDate.getDay() === 6
    ) {
      this.minDeliveryDate.setDate(this.minDeliveryDate.getDate() + 2);
    }
    this.maxDeliveryDate.setDate(this.minDeliveryDate.getDate());
    this.maxDeliveryDate.setMonth(this.maxDeliveryDate.getMonth() + 6);

    this.deliveryDate = this.minDeliveryDate;
    this.deliveryDateFormat = this.datepipe.transform(this.deliveryDate, 'yyyy-MM-dd');
  }

  setPickupDate() {
    if (this.currentTime < 12) {
      // Date Set
      this.minPickupDate.setDate(this.minPickupDate.getDate());
      if (
        this.minPickupDate.getDay() === 0 ||
        this.minPickupDate.getDay() === 6
      ) {
        for (let i = 1; i <= 3; i++) {
          this.minPickupDate.setDate(this.minPickupDate.getDate() + 1);
          if (
            this.minPickupDate.getDay() !== 0 &&
            this.minPickupDate.getDay() !== 6
          ) {
            break;
          }
        }
      }
      this.maxPickupDate.setDate(this.minPickupDate.getDate() + 6);
    } else {
      this.minPickupDate.setDate(this.minPickupDate.getDate() + 1);
      if (
        this.minPickupDate.getDay() === 0 ||
        this.minPickupDate.getDay() === 6
      ) {
        for (let i = 1; i <= 3; i++) {
          this.minPickupDate.setDate(this.minPickupDate.getDate() + 1);
          if (
            this.minPickupDate.getDay() !== 0 &&
            this.minPickupDate.getDay() !== 6
          ) {
            break;
          }
        }
      }
      this.maxPickupDate.setDate(this.minPickupDate.getDate() + 6);
    }
    this.pickupDate = this.minPickupDate;
  }

  setPickupTime() {
    if (this.currentTime < 10) {
      this.timeFormat = this.pickupTimeFormat.slice(3);
    } else if (this.currentTime === 10) {
      this.timeFormat = this.pickupTimeFormat.slice(4);
    } else if (this.currentTime === 11) {
      this.timeFormat = this.pickupTimeFormat.slice(6);
    } else if (this.currentTime > 11 && this.currentTime < 17) {
      this.timeFormat = this.pickupTimeFormat.slice(0);
    } else if (this.currentTime > 16) {
      this.timeFormat = this.pickupTimeFormat.slice(3);
    }
    this.selectedTime = _.first(this.timeFormat);
  }

  goToPLP(){
    this.route.goByUrl('/select-whs');
  }

  closeDisclaimerAlert() {
    this.showDisclaimer = false;
  }

  ngOnDestroy() {
    this.prodAddedSub?.unsubscribe();
    this.cartItemsSub?.unsubscribe();
    this.removeEntriesSub?.unsubscribe();
    this.deleteCartSub?.unsubscribe();
    this.cartDetailsSub?.unsubscribe();
    this.checkoutDetailsSub?.unsubscribe();
    this.checkoutFailedSub?.unsubscribe();
    this.userAccountDetailsSub?.unsubscribe();
    this.changedLocSub?.unsubscribe();
    this.saveCartInvalidSub?.unsubscribe();
    this.saveCartSuccessSub?.unsubscribe();
    this.plpMiniCartSub?.unsubscribe();
    this.userDataSub?.unsubscribe();
    this.globalMessage.remove(GlobalMessageType.MSG_TYPE_ERROR);
    this.cartItemsSub?.unsubscribe();
  }
}
