import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'localCurrency',
})
export class LocalCurrencyPipe implements PipeTransform {
  transform(
    value: number,
    currencyCode: string = 'EUR',
    locale: string = 'fr-FR'
  ): string | null {
    const options = { style: 'currency', currency: currencyCode };
    const formatter = new Intl.NumberFormat(locale, options);
    return formatter.format(value);
  }
}
