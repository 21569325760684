import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-open-link-popup',
  templateUrl: './open-link-popup.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OpenLinkPopUpComponent {
  @Input() public message;
  @Input() public linkUrl;

  constructor(private modalService: NgbModal, private cdr: ChangeDetectorRef) {}

  closeModal(): void {
    this.modalService.dismissAll();
  }
}
