<section class="container-fluid My-Account-Container">
  <section class="my-Account-Main-Header row">
    <section class="
        my-Account-Header-Name
        col-md-12 col-sm-12 col-lg-12 col-12 col-xl-12
        text-center
      ">
      {{'myAcountPandPre.myAcount'| cxTranslate}}
    </section>
  </section>
  <section class="account-Holder-Info-Div row mb-3 pb-3 pl-3">
    <section class="col-md-12 col-sm-12 col-lg-12 col-12 col-xl-12 mt-4">
      <h6 class="Account-Name">{{'myAcountPandPre.accountName'| cxTranslate}}</h6>
    </section>
    <section class="account-Details-Div w-100 pt-1 col-md-12 col-sm-12 col-lg-12 col-12 col-xl-12">
      <section class="account-Name-Label">
        <label class="labels-Of-Account account-Font-Values">{{'myAcountPandPre.name'| cxTranslate}}</label>
      </section>
      <section class="name-Of-Account">
        <span class="account-Name-Span account-Font-Values">{{defaultB2BUnitObject?.locName}}</span>
      </section>
    </section>
    <section class="account-Details-Div w-100 col-md-12 col-sm-12 col-lg-12 col-12 col-xl-12">
      <section class="account-Name-Label">
        <label class="labels-Of-Account account-Font-Values">{{'myAcountPandPre.phone'| cxTranslate}}</label>
      </section>
      <section class="name-Of-Account">
        <span class="account-Phone-No account-Font-Values">
          {{usergetData?.phone | phoneFormat }}</span>
      </section>
    </section>
    <section class="account-Details-Div w-100 col-md-12 col-sm-12 col-lg-12 col-12 col-xl-12">
      <section class="account-Name-Label">
        <label class="labels-Of-Account account-Font-Values">{{'myAcountPandPre.cepUid'| cxTranslate}}</label>
      </section>
      <section class="name-Of-Account">
        <span class="account-Phone-No account-Font-Values">{{defaultB2BUnitObject?.sapCustomerId}}</span>
      </section>
    </section>
    <section class="account-Details-Div w-100 col-md-12 col-sm-12 col-lg-12 col-12 col-xl-12">
      <section class="account-Name-Label">
        <label class="labels-Of-Account account-Font-Values">{{'myAcountPandPre.cipCode'| cxTranslate}}</label>
      </section>
      <section class="name-Of-Account">
        <span class="account-Phone-No account-Font-Values">{{defaultB2BUnitObject?.cipUid}}</span>
      </section>
    </section>
  </section>
  <section class="container address-Main-Div mt-2">
    <section class="billing-Div row">
      <section class="col-md-12 col-sm-12 col-lg-12 col-12 col-xl-12 mt-4">
        <h6 class="Account-Name">
          <img src="../../../assets/img/invoice.png" class="price-Mater-Icon" />
          {{'myAcountPandPre.billingAddress'| cxTranslate}}
        </h6>
      </section>
      <section class="address-Header-Div container ">
        <section class="row mt-2">
          <section class="
              col-md-12 col-sm-12 col-lg-12 col-12 col-xl-12
              w-100
              org-Name-Div
              mt-1
            ">
            <span> {{usergetData?.companyName}}</span>
          </section>
          <section class="
              col-md-12 col-sm-12 col-lg-12 col-12 col-xl-12
              w-100
              street-Address-Div
              mt-1
            ">
            <span>
              {{ usergetData?.line2 }}
              {{ usergetData?.line1 }}
            </span>
          </section>
          <section class="
              col-md-12 col-sm-12 col-lg-12 col-12 col-xl-12
              w-100
              city-Div
              mt-1
            ">
            <span>
              {{ usergetData?.postalCode ? usergetData?.postalCode : "" }}
              {{ usergetData?.town }}
            </span>
          </section>
        </section>
      </section>
    </section>
    <hr />
    <section class="payer-Div row mt-3" *ngIf="payerObject != null">
      <section class="payer-Icon-Div">
        <img src="/assets/icons/price-master.png" class="price-Mater-Icon" />
      </section>
      <section class="payer-Header-Div">
        <span class="header-Colour payer-Name-Header">{{'myAcountPandPre.payer'| cxTranslate}}</span>
      </section>
      <section class="address-Header-Div container ">
        <section class="row mt-2">
          <section class="
              col-md-12 col-sm-12 col-lg-12 col-12 col-xl-12
              w-100
              houseNumberDiv
              mt-1
            ">
            <span>{{payerObject?.unitID}}</span>
          </section>
          <section class="
              col-md-12 col-sm-12 col-lg-12 col-12 col-xl-12
              w-100
              org-Name-Div
              mt-1
            ">
            <span> {{payerObject?.companyName}}</span>
          </section>
          <section class="
              col-md-12 col-sm-12 col-lg-12 col-12 col-xl-12
              w-100
              street-Address-Div
              mt-1
            ">
            <span>{{payerObject?.line1}} {{payerObject?.line2}}</span>
          </section>
          <section class="
              col-md-12 col-sm-12 col-lg-12 col-12 col-xl-12
              w-100
              city-Div
              mt-1
            ">
            <span *ngIf="payerObject?.town != null"> {{ payerObject?.town+","}} {{ payerObject?.country?.name}} {{
              payerObject?.postalCode}}</span>
          </section>


        </section>
      </section>
    </section>

    <section class="img-Div"></section>

  </section>
</section>
