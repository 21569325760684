<div *ngIf="showDisclaimer" class="global-alerts">
  <div class="alert alert-info alert-dismissable getAccAlert">
    <button (click)="closeDisclaimerAlert()" class="close closeAccAlert" aria-hidden="true" data-dismiss="alert"
            type="button" data-di-id="di-id-4f6d7b93-70563360">×
    </button>
    {{"addToCartItems.disclaimerMsg" | cxTranslate}}
  </div>
</div>
<section class="order-update"><span>{{'addToCartItems.orderUpdated'|cxTranslate}}</span></section>
<section class="preheader">
  <section class="col-lg-12 col-md-12 col-sm-12 col-xs-12 float-right">
    <section class="col-lg-5 col-md-5 col-sm-0 col-xs-12 p0 float-left emptyheader"></section>
    <section class="col-lg-3 col-md-3 col-sm-6 col-xs-12 middle-row mt0 text-right float-left">
      <a href="/fr/fr/EUR/Open-Catalogue/c/1?query=:relevance:all:1:productType:INDIRECT" target="" id="continueShoppingId" data-di-id="#continueShoppingId">
        <img src="../../assets/icons/chevron-left.svg" alt="" class="chevron-left">
        {{'addToCartItems.continueShopping'|cxTranslate}}</a>
    </section>
    <section class="col-lg-4 col-md-4 col-sm-6 col-xs-12 text-right float-right p0 header-links">
      <section class="save-document save-document-container  mb1 mt0 ">
        <ng-template #popSaveTitle>{{'addToCartItems.saveOrderTitle'|cxTranslate}}</ng-template>
        <ng-template #popSaveContent>
          <input type="text" class="save-order" required [(ngModel)]="saveCartName"
            (keypress)="omit_special_char($event)">
          <span class="common-error required">{{errorMsg}}</span>
          <button type="reset" class="popover-close btn white-button popover-cancel-button" (click)="toggle(p2,0,true)">
            {{'addToCartItems.cancel'|cxTranslate}}</button>
          <button class="btn blue-button popover-cancel-button" type="submit" data-di-id="di-id-3064f17d-e97cc628"
            (click)="saveOrder(p2,false,orderConfirmationDetails.orders[0].code,0)">
            {{'addToCartItems.save'|cxTranslate}}</button>
        </ng-template>
        <a href="javascript:void(0)" target="" aria-label="download as" class="xls_cart xls-cart-img-section"
          id="tealium-xls" data-di-id="#tealium-xls" (click)="exportXls()">
          <img src="../../assets/icons/excel.png" alt="">
          <span>{{'addToCartItems.xls'|cxTranslate}} </span></a> <a href="javascript:void(0)" onclick="window.print();"
          target="" id="printLinkId" class="print-cart-img-section" data-di-id="#printLinkId">
          <img src="../../assets/icons/print.svg" alt="">
          <span>{{'addToCartItems.print'|cxTranslate}} </span></a>
      </section>
    </section>
  </section>
</section>
<section class="container influenzaCategory-Container">

  <section>

    <aside class="col-lg-4 col-md-4 col-sm-12 right-navigation float-right
      totals-width ord-tot-prnt p0">
      <section class="container est-Main-Container">
        <section class="row">
          <section class="col-md-12 col-lg-12 col-xl-12 text-center unit-Doses-Header">
          </section>
          <section class="col-md-12 col-lg-12 col-xl-12 text-center order-header">
            <span class="est-Header">
              {{'addToCartItems.TotalHt'|cxTranslate}}:
              {{ orderConfirmationDetails.totalPrice.value | localCurrency}} {{'plp.cad'|cxTranslate}} </span>
          </section>
          <section class="col-md-12 col-lg-12 col-xl-12 text-center declaration" *ngIf="orderConfirmationDetails.totalPrice.value > 0">
            <p>{{"orderConfirmation.disclaimerPrice" | cxTranslate}}</p>
          </section>
        </section>
      </section>
    </aside>
    <section class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 border-Right float-left">
      <section class="row">
        <section class="
              col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12
              checkOut-Header-Section
            ">
          <section class="firstLevel-acc checkout-heading-acc">
            <label class="fs-14 light-color col-md-7 col-sm-7 col-xs-12 p0">
              <ng-template #deliveryLoc>
                <img src="../../assets/icons/location.svg" class="accordion-img" alt="location">
                <span>{{orderConfirmationDetails.orders[0].shippingAddress?.companyName}}</span>
              </ng-template>
              <span *ngIf="orderConfirmationDetails.orders[0].deliveryMode?.code==='pickup'; else deliveryLoc"
                class="pickupLocation">
                <h6>{{'addToCartItems.pickLocation'|cxTranslate}}</h6>
                <p> {{'addToCartItems.sanofiName'|cxTranslate}}
                  <br>{{'addToCartItems.locationLine1'|cxTranslate}}<br>{{'addToCartItems.locationLine2'|cxTranslate}}
                </p>
              </span>
            </label>
            <section class="float-right sm-float-left purchase-no">
              <p>
                {{'orderConfirmation.orderTitle'|cxTranslate}}
              </p>
              <p *ngFor="let order of orderConfirmationDetails.orders; let i = index">
                {{'orderConfirmation.order'|cxTranslate : { index: i+1 } }}
                <span *ngIf="order?.entries[0]?.wholesalerProduct">{{order.code}}</span>
              </p>
              <p *ngIf="orderConfirmationDetails.orders[0].purchaseOrderNumber===''; else poNum">
                {{'addToCartItems.po'|cxTranslate}} {{'addToCartItems.orderspclchar'|cxTranslate}}
                <span>{{'addToCartItems.www'|cxTranslate}}{{orderConfirmationDetails.orders[0].created | slice:0:10 |
                  date:
                  "dd/MM/yyyy"}}</span>
              </p>
              <ng-template #poNum>
                <p>
                  {{'addToCartItems.po'|cxTranslate}} {{'addToCartItems.orderspclchar'|cxTranslate}}
                  <span>{{orderConfirmationDetails.orders[0].purchaseOrderNumber}}</span>
                </p>
              </ng-template>
            </section>
          </section>
          <hr [ngClass]="{'displayNone':screenwidth<=768}" />
        </section>
      </section>
      <section class="row shipping-Bill-Main-Section">
        <section class="col-12 col-sm-12 col-md-12 col-xl-4 col-lg-4
          po-Section-iPad">
          <section class="row">
            <section class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12 order-details">
              <span class="shipTo-Header"> {{'addToCartItems.soldTo'|cxTranslate}}</span>
              <p>#{{orderConfirmationDetails.orders[0].soldtoAddress?.unitID}}</p>
            </section>
            <section class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12 order-details">
              <span class="shipTo-Header">{{'addToCartItems.orderDate'|cxTranslate}}</span>
              <p>{{orderConfirmationDetails.orders[0].created | slice:0:10 | date:'dd/MM/yyyy'}}</p>
            </section>
          </section>
        </section>
        <section class=" col-12 col-sm-12 col-md-12 col-xl-4 col-lg-3 shipTo-Main-Section emptySection"
          *ngIf="orderConfirmationDetails.orders[0].pickupDate!==undefined"></section>
        <section class="col-12 col-sm-12 col-md-12 col-xl-4 col-lg-4
          po-Section-iPad shipto-label-section" *ngIf="orderConfirmationDetails.orders[0].pickupDate===undefined">
          <section class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12 order-details shipto-label">
            <span class="shipTo-Header">{{'addToCartItems.shipTo'|cxTranslate}}</span>
          </section>
          <section class="ship-add">
            <section class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12">
              <span class="">{{orderConfirmationDetails.orders[0].shippingAddress?.companyName ?
                orderConfirmationDetails.orders[0].shippingAddress?.companyName : '' }}</span>
            </section>
            <section class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12">
              <span class="">
                {{orderConfirmationDetails.orders[0].shippingAddress?.line2 ?
                orderConfirmationDetails.orders[0].shippingAddress?.line2 : '' }}
                {{orderConfirmationDetails.orders[0].shippingAddress?.line1 ?
                orderConfirmationDetails.orders[0].shippingAddress?.line1 : '' }}
              </span>
            </section>
            <section class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12">
              <span class="">
                {{orderConfirmationDetails.orders[0].deliveryAddress?.postalCode ?
                orderConfirmationDetails.orders[0].shippingAddress?.postalCode : '' }}
                {{orderConfirmationDetails.orders[0].deliveryAddress?.town ?
                orderConfirmationDetails.orders[0].deliveryAddress?.town : ''}}
              </span>
            </section>
          </section>
        </section>
        <section class=" col-12 col-sm-12 col-md-12 col-xl-5 col-lg-5 total-Price-Section ">
          <app-indirect-total-prices [abstractOrder]="orderConfirmationDetails"></app-indirect-total-prices>
        </section>
        <section class="charges-Msg-Div"
          *ngIf="orderConfirmationDetails.orders[0].subTotal?.value<250 && orderConfirmationDetails.orders[0].pickupDate===undefined">
          <span>{{'addToCartItems.shippingChargesNote'|cxTranslate}}
          </span>
        </section>
      </section>

      <section class="car-Row-Container" *ngFor="let order of orderConfirmationDetails.orders; let i = index">

        <div class="row order-heading-section">
          <div class="col-xs-6">
            <h3 *ngIf="showOrderNumber">Commande {{i+1}}</h3>
          </div>
          <div *ngIf="orderConfirmationDetails.orders.length > 1" class="col-xs-6 text-right">
            <section class="save-document save-document-container  mb1 mt0 ">
              <ng-template #popSaveTitle>{{'addToCartItems.saveOrderTitle'|cxTranslate}}</ng-template>
              <ng-template #popSaveContent2>
                <input type="text" class="save-order" required [(ngModel)]="saveCartName"
                  (keypress)="omit_special_char($event)">
                <span class="common-error required">{{errorMsg}}</span>
                <button type="reset" class="popover-close btn white-button popover-cancel-button"
                  (click)="toggle(p3,i)">
                  {{'addToCartItems.cancel'|cxTranslate}}</button>
                <button class="btn blue-button popover-cancel-button" type="submit" data-di-id="di-id-3064f17d-e97cc628"
                  (click)="saveOrder(p3,true,order.code,i)">
                  {{'addToCartItems.save'|cxTranslate}}</button>
              </ng-template>
            </section>
          </div>
        </div>


        <section class="row cart-Row" [ngClass]="orderConfirmationDetails.orders.length > 1 ? 'multiple-order' : ''"
          *ngFor="let productEntry of order.entries">
          <section class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 prod-Image-Section">
            <a (click)="productDetails(productEntry)">
              <ng-container *ngFor="let img of productEntry.product.images">
                <img class="prod-Image" *ngIf="img.format == 'thumbnail'; else altImg" [src]="baseUrl+img.url"
                  alt="product">
                <ng-template #altImg *ngIf="img == undefined">
                  <img class="prod-Image" [src]="fallbackImage" alt="product">
                </ng-template>
              </ng-container>
            </a>
          </section>
          <section class="col-11 col-sm-11 col-md-5 col-lg-8 col-xl-5 prod-Disc-Div">
            <section class="container">
              <section class="row">
                <section class="col-md-12 col-lg-12 col-xl-12 prod-header">
                  <a (click)="productDetails(productEntry)">
                    <h2 class="prodcut-Name" [innerHTML]="productEntry?.product?.name">

                    </h2>
                  </a>
                </section>
                <section class="col-sm-12 col-md-11 col-lg-8 prod-table">
                  <section class="table-responsive">
                    <table class="table" aria-describedby="products">
                      <thead>
                        <tr>
                          <th scope="col">{{'plp.units'|cxTranslate}}</th>
                          <th scope="col">{{'plp.yourPrice'|cxTranslate}}</th>

                          <th scope="col">{{'addToCartItems.subtotal'|cxTranslate}}</th>
                          <th scope="col">{{'addToCartItems.status'|cxTranslate}}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{{productEntry?.quantity}}
                          </td>
                          <td>{{productEntry?.basePrice?.value | localCurrency}} {{'plp.cad'|cxTranslate}}</td>
                          <td>{{productEntry?.totalPrice?.value| localCurrency}} {{'plp.cad'|cxTranslate}}</td>
                          <td>{{'addToCartItems.statusOpen'|cxTranslate}}</td>
                        </tr>

                      </tbody>
                    </table>
                  </section>
                </section>
                <section class="col-md-12 col-lg-12 col-xl-12 product-Desc-Section">
                  <p [innerHTML]="productEntry?.product?.description"></p>
                </section>
                <section class="col-md-12 col-lg-12 col-xl-12 prod-No-Section">
                  {{'selectVaccines.cipCode'|cxTranslate}}:
                  <span class="prdct-No"
                    *ngIf="productEntry?.product?.cipCode !== undefined">{{productEntry?.product?.cipCode}}</span>
                  <p class="message-confirmation-area" *ngIf="productEntry.product?.messageDangerousGoods">
                    {{'plp.messageDangerousGoods'|cxTranslate}}</p>
                  <p class="message-confirmation-area" *ngIf="productEntry.product?.messageColdChain">
                    {{'plp.messageColdChain'|cxTranslate}}</p>
                </section>
                <section *ngIf="!!productEntry?.wholesalerDisplayName" class="col-md-12 col-lg-12 col-xl-12 prod-No-Section">
                  {{ "selectVaccines.wholesalerName" | cxTranslate }}:
                  <span class="prdct-No">{{ productEntry?.wholesalerDisplayName }}</span>
                </section>
              </section>
            </section>
          </section>
        </section>
      </section>
    </section>

    <section class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4
          right-Section-Top-Col-Span float-right
        ">

      <section class="container reservation-Container">
        <section class="row">
          <section class="col-md-12 col-lg-12 col-xl-12">
            <section class="row">
              <section class="col-8 col-sm-8 col-md-8 order-Contact">{{'addToCartItems.orderContact'|cxTranslate}}
              </section>
            </section>
          </section>
          <section class="col-md-12 col-lg-12 col-xl-12"></section>
        </section>

        <section class="row contact-Main-Row">
          <section class=" col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 resName-Div">
            <span class="res-Name">{{'addToCartItems.onsnName'|cxTranslate}}</span>
          </section>
          <section class="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 resName-Value-Div">
            <span class="">{{orderConfirmationDetails.orders[0].orderContact?.name}}</span>
          </section>
          <section class=" col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 resPhone-Div">
            <span class="res-Phone">{{'addToCartItems.landline'|cxTranslate}}</span>
          </section>
          <section class=" col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 resPhone-Value-Div">
            <span class="">{{orderConfirmationDetails.orders[0].orderContact?.phoneNo ?
              orderConfirmationDetails.orders[0].orderContact?.phoneNo : '' }}</span>
          </section>
          <section class=" col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 resPhone-Div"
            *ngIf="orderConfirmationDetails.orders[0].orderContact?.mobileNo">
            <span class="res-Phone">{{'addToCartItems.mobile'|cxTranslate}}</span>
          </section>
          <section class=" col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 resPhone-Value-Div"
            *ngIf="orderConfirmationDetails.orders[0].orderContact?.mobileNo">
            <span class="">{{orderConfirmationDetails.orders[0].orderContact?.mobileNo}}</span>
          </section>
          <section class=" col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 resRole-Div"
            *ngIf="orderConfirmationDetails.orders[0].orderContact?.role">
            <span class="res-Phone">{{'addToCartItems.role'|cxTranslate}}</span>
          </section>
          <section class=" col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 resRole-Value-Div"
            *ngIf="orderConfirmationDetails.orders[0].orderContact?.role">
            <span class="">{{orderConfirmationDetails.orders[0].orderContact?.role}}</span>
          </section>
          <section class=" col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 resEmail-Div">
            <span class="res-Email">{{'addToCartItems.email'|cxTranslate}}</span>
          </section>
          <section class=" col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 resEmail-Value-Div">
            <a href="{{'mailto:'+orderConfirmationDetails.orders[0].orderContact?.email}}"
              class="email-Value">{{orderConfirmationDetails.orders[0].orderContact?.email}}</a>
          </section>
        </section>

        <section class="row">
          <section class="col-md-6 col-lg-12 col-xl-6  soldTo-Main-Div">
            <section class="container">
              <section class="row">
                <section class="col-md-12 col-lg-12 col-xl-12 sold-Address-Div">
                  <span class="sold-Address-Header">{{'addToCartItems.soldToAddress'|cxTranslate}}</span>
                </section>
                <section class="col-md-12 col-lg-12 col-xl-12">
                  <span>{{orderConfirmationDetails.orders[0].soldtoAddress?.companyName ?
                    orderConfirmationDetails.orders[0].soldtoAddress?.companyName :
                    ''}}</span>
                </section>
                <section class="col-md-12 col-lg-12 col-xl-12">
                  <span>
                    {{orderConfirmationDetails.orders[0].soldtoAddress?.line2 ?
                    orderConfirmationDetails.orders[0].soldtoAddress?.line2 : '' }}
                    {{orderConfirmationDetails.orders[0].soldtoAddress?.line1 ?
                    orderConfirmationDetails.orders[0].soldtoAddress?.line1 : '' }}
                  </span>
                </section>
                <section class="col-md-12 col-lg-12 col-xl-12">
                  <span>
                    {{orderConfirmationDetails.orders[0].soldtoAddress?.postalCode ?
                    orderConfirmationDetails.orders[0].soldtoAddress?.postalCode : '' }}
                    {{orderConfirmationDetails.orders[0].soldtoAddress?.town ?
                    orderConfirmationDetails.orders[0].soldtoAddress?.town: ''}}
                  </span>
                </section>
              </section>
            </section>
          </section>
          <section class="col-md-12 manage-Billing-Div">
            <a target="_blank" (click)="billingAccount()">
              <span class="manage-Billing-info">{{'addToCartItems.billingInfo'|cxTranslate}}</span>
            </a>
          </section>
        </section>
      </section>
    </section>

  </section>
</section>
