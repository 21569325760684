<section (mouseleave)="closepop()" class="cart-icons" id="miniCart" *ngIf="showCartIcon">
  <a class="clear-cart" *ngIf="isEmpty && !isFlu && !isIndirect" [ngbPopover]=popContentEmpty [popoverTitle]=popTitleEmpty
    (mouseenter)="p1.open()" #p1="ngbPopover" placement="bottom" (click)="toggleWithGreeting(p1)">
    <img class="pdf-icon" src="../../assets/icons/cart.png" alt="">
  </a>
  <a class="clear-cart" *ngIf="!isEmpty && !isFlu && !isIndirect" [ngbPopover]=popContentStd [popoverTitle]=popTitleStd
    popoverClass="minicart-std-popover" (mouseenter)="p2.open()" #p2="ngbPopover" placement="bottom"
    (click)="toggleWithGreeting(p2)">
    <img class="pdf-icon" src="../../assets/icons/cart.png" alt="" [ngbPopover]=popTitleSessionCart #c1="ngbPopover"
      triggers="manual" placement="bottom" popoverClass="itemincart-success-msg" (click)="c1.close()" container="body">
    <p class="cartvalue">{{quantityDisplay}}</p>
  </a>
  <a class="clear-cart" *ngIf="!isEmptyFlu && isFlu" [ngbPopover]=popContentFlu [popoverTitle]=popTitleFlu
    (mouseenter)="p4.open()" #p4="ngbPopover" placement="bottom" (click)="toggleWithGreeting(p4)">
    <img class="pdf-icon" src="../../assets/icons/cart.png" alt="">
    <p class="cartvalue">{{quantityDisplayFlu}}</p>
  </a>
  <a class="clear-cart" *ngIf="isEmptyFlu && !isIndirect && isFlu" [ngbPopover]=popContentEmpty [popoverTitle]=popTitleEmpty
     (mouseenter)="p1.open()" #p1="ngbPopover" placement="bottom" (click)="toggleWithGreeting(p1)">
    <img class="pdf-icon" src="../../assets/icons/cart.png" alt="">
  </a>

  <a class="clear-cart" *ngIf="!isEmptyIndirect && isIndirect && !isFlu" [ngbPopover]=popContentInd [popoverTitle]=popTitleInd
     (mouseenter)="p3.open()" #p3="ngbPopover" placement="bottom" (click)="toggleWithGreeting(p3)">
    <img class="pdf-icon" src="../../assets/icons/cart.png" alt="">
    <p class="cartvalue" *ngIf="!quantityDisplayIndirect == '' ">{{quantityDisplayIndirect}}</p>
  </a>
  <a class="clear-cart" *ngIf="isEmptyIndirect && isIndirect && !isFlu" [ngbPopover]=popContentEmpty [popoverTitle]=popTitleEmpty
     (mouseenter)="p1.open()" #p1="ngbPopover" placement="bottom" (click)="toggleWithGreeting(p1)">
    <img class="pdf-icon" src="../../assets/icons/cart.png" alt="">
  </a>


</section>
<!-- EMPTY-->
<section class="cart-popover">
  <ng-template #popTitleEmpty>
    <div class="minicart-header">
      <span class="mini-triangle"></span>
      <span class="mini-cart-title"></span>
      {{'addToCartItems.emptycart'|cxTranslate}}
    </div>
  </ng-template>
  <ng-template #popContentEmpty>
    <div class="miniCart-listitem">
      <ul>
        <li>
          <a title="Order products" (click)="navigateToPdp()">{{'addToCartItems.emptyOrderProduct'|cxTranslate}}</a>
        </li>
        <li *ngIf="!!accountType && accountType == AccountType.Pharmacy">
          <a title="" (click)="navigateToFlu()">{{'addToCartItems.emptyFluDashboard'|cxTranslate}}</a>
        </li>
      </ul>
    </div>
  </ng-template>
</section>

<!-- STANDARD-->
<section class="cart-popover">
  <ng-template #popTitleStd>
    <div class="minicart-header">
      <span class="mini-triangle"></span>
      <span class="mini-cart-title"></span>
      {{'addToCartItems.orderSubtotal'|cxTranslate}} {{stdCartEntries?.subTotal?.value | localCurrency}}
    </div>
  </ng-template>
  <ng-template #popContentStd>
    <section class="cart-prod-list">
      <section class="unit-numbers">
        <span class=""> {{totalQuantity}} {{'addToCartItems.units'|cxTranslate}} </span>
      </section>
      <section class="cart-details-minicart">
        <ul class="cart-prod-details" *ngFor="let productentry of productEntries.slice().reverse()">
          <li>

            <a class="miniCart-prod-details" (click)="navigatetoProduct(productentry?.product?.code)">
              <img *ngIf="productentry?.product?.images!= null" class="flu-poster img_loaded"
                src="{{productentry.PRIMARY.url}}" alt="">
              <img *ngIf="productentry?.product?.images == null" [src]="fallbackImage" alt=""
                class="flu-poster img_loaded">

              <span class="cart-prod-title" [innerHTML]="productentry?.product?.name"></span></a>
            <span class="minicart-prod-doses">{{productentry?.quantity}}</span>
            <p class="miniCart-price">{{productentry?.basePrice?.value | localCurrency}}
              {{'plp.cad'|cxTranslate}}
            </p>
          </li>
        </ul>
      </section>
      <section class="cart-footer savepopover">
        <a role="presentation" data-di-id="di-id-c676ac20-ae73fdd6">
          <button type="button" (click)="navigateToCart()" class="btn btn-default text-button-initial blue-button">
            {{'addToCartItems.viewcart'|cxTranslate}} </button>
        </a>
      </section>
    </section>
  </ng-template>
</section>

<!-- INDIRECT-->
<section class="cart-popover">
  <ng-template #popTitleInd>
    <div class="minicart-header">
      <span class="mini-triangle"></span>
      <span class="mini-cart-title"></span>
      {{'addToCartItems.orderSubtotal'|cxTranslate}} {{indCartEntries?.subTotal?.value | localCurrency}}
    </div>
  </ng-template>
  <ng-template #popContentInd>
    <section class="cart-prod-list">
      <section class="unit-numbers">
        <span class=""> {{totalQuantityIndirect}} {{'addToCartItems.units'|cxTranslate}} </span>
      </section>
      <section class="cart-details-minicart">
        <ul class="cart-prod-details" *ngFor="let productentry of productIndirectEntries.slice().reverse()">
          <li>
            <a class="miniCart-prod-details" (click)="navigatetoProduct(productentry?.product?.code)">
              <img *ngIf="productentry?.product?.images!= null" class="flu-poster img_loaded"
                   src="{{productentry.PRIMARY.url}}" alt="">
              <img *ngIf="productentry?.product?.images == null" [src]="fallbackImage" alt=""
                   class="flu-poster img_loaded">

              <span class="cart-prod-title" [innerHTML]="productentry?.product?.name"></span></a>
            <span class="minicart-prod-doses">{{productentry?.quantity}}</span>
            <p class="miniCart-price">{{productentry?.basePrice?.value | localCurrency}}
              {{'plp.cad'|cxTranslate}}
            </p>
          </li>
        </ul>
      </section>
      <section class="cart-footer savepopover">
        <a role="presentation" data-di-id="di-id-c676ac20-ae73fdd6">
          <button type="button" (click)="navigateToIndirectCart()" class="btn btn-default text-button-initial blue-button">
            {{'addToCartItems.viewcart'|cxTranslate}} </button>
        </a>
      </section>
    </section>
  </ng-template>
</section>

<!-- FLU-->
<section class="cart-popover">
  <ng-template #popTitleFlu>
    <div class="minicart-header">
      <span class="mini-triangle"></span>
      <span class="mini-cart-title"></span>
      {{'addToCartItems.orderSubtotal'|cxTranslate}} {{fluCartEntries?.subTotal?.value | localCurrency}}
      {{fluCartEntries?.subTotal?.currencyIso}}
    </div>
  </ng-template>
  <ng-template #popContentFlu>
    <section class="cart-prod-list">
      <section class="unit-numbers">
        <span class="">{{totalQuantityFlu}} {{'addToCartItems.units'|cxTranslate}}</span>
      </section>
      <section class="cart-details-minicart">
        <ul class="cart-prod-details" *ngFor="let productentry of productFluEntries">
          <li>
            <a (click)="navigatetoProduct(productentry?.product?.code)">
              <span class="miniCart-prod-details">
                <img *ngIf="productentry?.product?.images!= null" class="flu-poster img_loaded"
                  src="{{productentry.PRIMARY.url}}" alt="">
                <img *ngIf="productentry?.product?.images == null" [src]="fallbackImage" alt=""
                  class="flu-poster img_loaded">
              </span>
              <span class="cart-prod-title" [innerHTML]="productentry?.product?.name"></span></a>
            <span class="minicart-prod-doses">{{productentry?.quantity}}</span>
            <p class="miniCart-price flu-minicart-price">{{productentry?.basePrice?.value | localCurrency}}
              {{'plp.cad'|cxTranslate}}
            </p>
          </li>
        </ul>
      </section>
      <section class="cart-footer savepopover">
        <a role="presentation" data-di-id="di-id-c676ac20-ae73fdd6">
          <button type="button" (click)="navigateToFlufromCart()" class="btn btn-default blue-button ">
            {{'addToCartItems.viewcart'|cxTranslate}}</button>
        </a>
      </section>
    </section>
  </ng-template>
</section>


<section class="added-success-container">
  <ng-template #popTitleSuccess>
    <div class="cart-value-added">
      <img src="../../../assets/icons/confirmation-positive-green.png" class="positive-green-icon" alt="success">
      <span class="confirmation-itemsadded">{{itemAddedQuantity}}
        {{ ('addToCartItems.' + (hospital ? 'addEntrySuccessHospital' : 'addEntrySuccess')) | cxTranslate }}</span>
    </div>
  </ng-template>
</section>
<ng-template #popTitleSaveSuccess>
  <div class="cart-value-added">
    <img src="../../../assets/icons/confirmation-positive-green.png" class="positive-green-icon" alt="success">
    <span class="confirmation-itemsadded">{{cartSavedSuccess}} {{'addToCartItems.savesuccess'|cxTranslate}}</span>
  </div>
</ng-template>

<ng-template #popMinicartItems>
  <div class="cart-value-added">
    <img src="../../../assets/icons/confirmation-positive-green.png" class="positive-green-icon" alt="success">
    <span class="confirmation-itemsadded">{{quantityDisplay}} {{'addToCartItems.sessionCartMsg'|cxTranslate}}</span>
  </div>
</ng-template>
<ng-template #popTitleSessionCart>
  <div class="cart-value-added">
    <img src="../../../assets/icons/info.png" class="positive-green-icon" alt="info">
    <span class="confirmation-itemsadded">{{quantityDisplay}} {{'addToCartItems.sessionCartMsg'|cxTranslate}}</span>
  </div>
</ng-template>
