
  <section class="
      col-xs-12 col-sm-8 col-md-8
      left-section
      Registration-nav-section
      section-height
    ">
    <div class="col-xs-12 col-sm-12 col-md-12 Registration-split recovery-split">
      <h3 class="reset-password-title">
        {{ "resetPassword.title" | cxTranslate }}
      </h3>
      <span class="security-Notify">{{
        "resetPassword.CondtionList" | cxTranslate
        }}</span>
      <ul class="required-ul">
        <li>{{ "resetPassword.lowercaseLetter" | cxTranslate }}</li>
        <li>{{ "resetPassword.uppercaseLetter" | cxTranslate }}</li>
        <li>{{ "resetPassword.number" | cxTranslate }}</li>
        <li>{{ "resetPassword.specialCharacters" | cxTranslate }}</li>
      </ul>
      <div class="error-message" *ngIf="invalidToken">
        {{ "resetPassword.invalidAccessToken" | cxTranslate }}</div>
      <form [formGroup]="passwordForm">
        <div class="col-xs-12 col-sm-8 col-md-8 p0">
          <div class="col-xs-12 col-sm-12 col-md-12 p0 Fields-container">
            <label *ngIf="registrationToken == undefined || registrationToken == null" for="Password" class="Fields-label">{{ "resetPassword.newPassword" | cxTranslate }}
            </label>
            <label *ngIf="registrationToken != undefined && registrationToken != null" for="Password" class="Fields-label">{{ "resetPassword.createPassword" | cxTranslate }}
            </label>
            <input id="newPassword" name="newPassword" type="password" class="Fields-input"
              (ngModelChange)="validatePassword()" formControlName="newPassword" autocomplete="off" />
            <span role="alert" aria-live="assertive" *ngIf="
                passwordIsNotValid &&
                !passwordForm.get('newPassword').hasError('required')
              " class="error-message">{{ "resetPassword.validPassword" | cxTranslate }}</span>
            <span role="alert" aria-live="assertive" *ngIf="
                (passwordForm.get('newPassword').hasError('required') &&
                  passwordForm.get('newPassword').touched) ||
                (passwordRequired && !passwordIsNotValid)
              " class="error-message">{{ "forgotUserPassword.requiredField" | cxTranslate }}</span>
          </div>

          <div class="col-xs-12 col-sm-12 col-md-12 p0 Fields-container">
            <label *ngIf="registrationToken == undefined || registrationToken == null" for="confirmPassword" class="Fields-label">{{
              "resetPassword.confirmNewPassword" | cxTranslate
              }}</label>
            <label *ngIf="registrationToken != undefined && registrationToken != null" for="confirmPassword" class="Fields-label">{{
              "resetPassword.confirmCreatedPassword" | cxTranslate
              }}</label>
            <input name="checkPwd" type="password" class="Fields-input" formControlName="confirmPassword"
              autocomplete="off" (ngModelChange)="validateConfirmPassword()" />
            <span role="alert" aria-live="assertive" *ngIf="
                (passwordForm.get('confirmPassword').hasError('required') &&
                  passwordForm.get('confirmPassword').touched) ||
                confirmPasswordRequired
              " class="error-message">{{ "forgotUserPassword.requiredField" | cxTranslate }}</span>
            <span role="alert" aria-live="assertive" *ngIf="
                !passwordForm.get('confirmPassword').hasError('required') &&
                passwordForm.get('newPassword').value !==
                  passwordForm.get('confirmPassword').value
              " class="error-message">{{ "resetPassword.fieldsMismatchMsg" | cxTranslate }}</span>
          </div>

          <span class="reset-warning-message" *ngIf="registrationToken == undefined || registrationToken == null">{{
            "resetPassword.newPasswordWarningMessage" | cxTranslate
            }}</span>
          <span class="reset-warning-message" *ngIf="registrationToken != undefined && registrationToken != null">{{
            "resetPassword.createPasswordWarningMessage" | cxTranslate
            }}</span>
          <div class="Fields-btn-group">
            <button *ngIf="registrationToken == undefined || registrationToken == null" type="submit" class="btn btn-default blue-button submit-btn" (click)="onSubmit()">
              {{ "forgotUserPassword.submitForgot" | cxTranslate }}
            </button>
            <button *ngIf="registrationToken != undefined && registrationToken != null" type="submit" class="btn btn-default blue-button create-btn" (click)="onSubmit()">
              {{ "forgotUserPassword.submitCreate" | cxTranslate }}
            </button>
          </div>
        </div>
      </form>
      <br />
    </div>
  </section>
