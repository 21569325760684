<div class="container header-Container">
  <div class="row">
    <div class="col-lg-3 col-xl-3 ">
      <span class="activeSeasonId">
        {{'selectVaccines.season'|cxTranslate}} {{activeSeasonId}}
      </span>

    </div>
    <div class="col-7 col-lg-7 col-xl-7 bc-Dash-Div no-print">
      <span class="back-To-Dashbaord" (click)='backToDashBoard(content,false)'>
        <img src='assets/img/chevron-left.svg' class="svg-Images" alt="left">
        {{'selectVaccines.back_to_db'|cxTranslate}}</span>
    </div>
    <div class="col-3 col-lg-2 col-xl-2 text-right print-Div no-print">
      <span class="print" (click)="OnFluPrint()">
        <img src='assets/img/print.svg' class="svg-Images" alt="print">
        {{'selectVaccines.print'|cxTranslate}}</span>
    </div>

  </div>
</div>

<app-flu-header [selectedHeader]="header"></app-flu-header>


<!---------------------------------------select vaccine------------------------------->

<section class="container select-Container">
  <section class="row">
    <!-- =====================iphone======================= -->
    <section class="container proceed-small-Container proceed-BtnView no-print">
      <section class="row">
        <section class="col-md-12 col-lg-12 col-xl-12">
          <button class="btn proceed-Btn" [ngClass]="{'block-Click':apiCallUnderProcess}"
            [disabled]="duplicateProdCheckArray.length===0 || disableButton" (click)="validateQuantity()">
            <i class="fa fa-spinner fa-spin" *ngIf="apiCallUnderProcess"></i>
            {{'selectVaccines.proceed_toCart'|cxTranslate}}</button>
        </section>
      </section>
    </section>
    <!--left sec-->
    <section class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 bord-Right">
      <section class="row">
        <section class="col-xl-12 col-lg-12 select-Header-Section">
          <section class="select-Header">
            <ng-container *ngIf="hasConfirmedQuantity(0); else noConfirmedQuantity">
              {{ 'selectVaccines.editFluHeader' | cxTranslate }}
            </ng-container>
            <ng-template #noConfirmedQuantity>
              {{ 'selectVaccines.select_Inf_Msg' | cxTranslate }}
            </ng-template>
          </section>
        </section>
      </section>
      <br>
      <section class="row row-Gap" *ngFor="let product of fluProductsList; let i = index" id="{{product.code}}">
        <!-- Image -->
        <section class="col-2 col-sm-1 col-md-1 col-lg-1 col-xl-1 prod-Image-Section">
          <a (click)="productDetails(product)">
            <img *ngIf="product.thumbNailImg !== ''; else undefinedImg" src="{{baseUrl}}{{product.thumbNailImg}}"
                 class="prod-Image" title="{{product.name}}" alt="{{product.name}}" />
            <ng-template #undefinedImg>
              <img [src]="fallbackImage" class="prod-Image" title="{{product.name}}" alt="fallback-image" />
            </ng-template>
          </a>
        </section>
        <!-- Title -->
        <section class="col-10 col-sm-10 col-md-5 col-lg-5 col-xl-5 prod-Desc-Div">
          <section class="container">
            <section class="row">
              <section class="col-md-12 col-lg-12 col-xl-12">
                <a (click)="productDetails(product)" title="{{product.name}}" class="prod-Name" [innerHTML]="product.name"></a>
              </section>
              <section class="col-md-12 col-lg-12 col-xl-12 product-Desc-Section" [innerHTML]="product.description">
              </section>
              <section class="col-md-12 col-lg-12 col-xl-12 prod-No" *ngIf="!hospital && product?.cipCode !== undefined">
                {{"selectVaccines.cipCode" | cxTranslate}}:
                <span class="prodct-No">{{product?.cipCode}}</span>
              </section>
              <section class="col-md-12 col-lg-12 col-xl-12 prod-No" *ngIf="hospital && product?.ucdCode !== undefined">
                {{"selectVaccines.ucdCode" | cxTranslate}}:
                <span class="prodct-No">{{product?.ucdCode}}</span>
              </section>
            </section>
          </section>
        </section>
        <!-- Price -->
        <section class="col-8 col-sm-8 col-md-3 col-lg-3 col-xl-3 price-Main-Section">
          <section class="container">
            <section class="row">
              <!-- catalog -->
              <section class="col-md-12 col-lg-12 col-xl-12 original-price">
                <span class="your-Price">{{"selectVaccines.catalogPrice" | cxTranslate}}</span>
                <br>
                <p class="product-Price">{{product?.catalogPrice?.value | localCurrency}}</p>
              </section>

              <!-- purchase-->
              <section class="col-md-12 col-lg-12 col-xl-12">
                <span class="your-Price">{{"selectVaccines.purchasePrice" | cxTranslate}}</span>
                <br>
                <span class="product-Price">{{ product?.price?.value | localCurrency }}</span>
              </section>
            </section>
          </section>
        </section>
        <!-- Units -->
        <section class="col-4 col-sm-4 col-md-3 col-lg-3 col-xl-3 p0 pad-Port">
          <section class="container p0">
            <section class="units" *ngIf="(product.available===true && product.discontinued===false) else NotAccept">
              <strong class="prodUnits">{{'selectVaccines.doses'|cxTranslate}}</strong>
              <section class="unit-area">
                <span class="disabled " aria-hidden="true" id="{{'minusIcon'+product?.code}}">
                  <img src="../../assets/icons/less.svg" id="{{'unitMinus'+product?.code}}" class="disabled-img" alt=""
                       (click)="performQuantityComputation(2,product?.code,product?.stepQuantity,product?.minQuantity,product?.maxQuantity)">
                  <img src="../../assets/icons/less-disable.svg" id="{{'unitMinusDisable'+product?.code}}" alt="">
                </span>
                <input type="number" class="prod-quantity text-center" *ngIf="!product?.numberOfDosesPerUnit; else numberOfBoxesInput"
                       value="{{product?.totalQuantity ? product?.totalQuantity : '0'}}"
                       onkeypress="return /[0-9a-zA-Z]/i.test(event.key)"
                       (input)="onQuantityChange(product?.code, $event.target.value,product?.stepQuantity,product?.minQuantity,product?.maxQuantity)"
                       (keyup)="performQuantityComputation(null,product?.code,product?.stepQuantity,product?.minQuantity,product?.maxQuantity)"
                       id="{{'prodQuantity'+product?.code}}" min="0" max="99999" />
                <ng-template #numberOfBoxesInput >
                    <input
                       type="number"
                       class="prod-quantity text-center"
                       [value]="roundedValue ? roundedValue : product?.numberOfDosesPerUnit"
                       (input)="userInput = $event.target.value;"
                       (blur)="roundToNearestMultipleOfStepQuantity(product?.stepQuantity,product?.code)"
                       id="{{'prodQuantity'+product?.code}}" min="1" max="9999"
                       />
              </ng-template>
                <span aria-hidden="true" id="{{'plusIcon'+product?.code}}">
                  <img src="../../assets/icons/more.svg"
                       (click)="performQuantityComputation(1,product?.code,product?.stepQuantity,product?.minQuantity,product?.maxQuantity)"
                       id="{{'unitPlus'+product?.code}}" alt="">
                  <img src="../../assets/icons/more-disable.svg" id="{{'unitPlusDisable'+product?.code}}"
                    class="disabled-img" alt="">
                </span>
              </section>

                <section *ngIf="product?.numberOfDosesPerUnit else noDoses" class="doses-section" id="{{'noOfDoses'+product?.code}}">
                  {{boxesQuantity}} {{'plp.boxes'|cxTranslate}}
                </section>

                <ng-template #noDoses>
                  <section *ngIf="product?.stepQuantity" class="doses-section d-none" id="{{'noOfDoses'+product?.code}}"></section>
                </ng-template>


              <span class="available-now"
                *ngIf="product.onsn === 'ON' && product.discontinued===false && product.available===true">
                {{'selectVaccines.available_now'|cxTranslate}}
              </span>
            </section>

            <ng-template #NotAccept>
              <section class="units-Non-Accepting">
                <span *ngIf="!product.available && product.discontinued===false" class="Not-Available">
                  {{product?.availabilityText ? product?.availabilityText :
                  ('selectVaccines.product_unavailable'|cxTranslate)}}
                </span>
                <span class="" *ngIf="product.discontinued===true" class="Not-Available">
                  {{'selectVaccines.product_discontinued'|cxTranslate}}</span>
              </section>
            </ng-template>

          </section>
        </section>
      </section>
    </section>
    <!--down sec-->
    <section class="container square-Container banner-Section small-Device-Section">
      <section class="row">
        <section class="col-md-12 col-lg-12 col-xl-12">
          <app-flu-dashboard-banner [bannerComponent]="compName"></app-flu-dashboard-banner>
        </section>
      </section>
    </section>
    <!--right sec-->
    <section class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 no-print">
      <section class="row proceed-Container proceed-BtnNormal">
        <section class="col-md-12 col-lg-12 col-xl-12">
          <button class="btn proceed-Btn text-button-initial"
                  (click)="validateQuantity()"
                  [ngClass]="{'block-Click':apiCallUnderProcess}"
                  [disabled]="duplicateProdCheckArray.length===0 || disableButton || disableNextButton">
            <i class="fa fa-spinner fa-spin" *ngIf="apiCallUnderProcess"></i>
            {{'selectVaccines.proceed_toCart'|cxTranslate}}
          </button>
          <!-- Error message for minimum threshold, shown only if minThresholdError is true -->
          <div *ngIf="minThresholdError" class="error-message">
            {{ 'selectVaccines.minThresholdError' | cxTranslate:{ threshold: minThresholdValue } }}
          </div>
        </section>

      </section>
      <section class="banner-Section big-Device-Section">
        <app-splitted-deliveries-paragraph></app-splitted-deliveries-paragraph>
      </section>
    </section>

  </section>
</section>
<section class="custome-modal">
  <ng-template #content let-modal id="content-modal-data-id">
    <section class="modal-header">
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <img alt="" src="../../assets/icons/close.png">
      </button>
      <h4 class="modal-title" id="modal-basic-title">{{'selectVaccines.discard_Changes'|cxTranslate}}</h4>
    </section>
    <section class="modal-body modal-body-discard">

      <span>{{'selectVaccines.leavePage_Msg'|cxTranslate}}</span>
    </section>
    <section class="modal-footer">
      <section class="row w-100 m-0">
        <section class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
          <button type="button" class="btn yesBtn-Css" (click)="modal.close('Save click'); backToDashBoard('',true) ">
            {{'selectVaccines.yes'|cxTranslate}}
          </button>
        </section>
        <section class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
          <button type="button" class="btn cancelBtn-Css" (click)="modal.close('Save click')">
            {{'selectVaccines.no'|cxTranslate}}
          </button>
        </section>
      </section>

    </section>
  </ng-template>


</section>
