<section class="row registrationView" id="productList-area">
  <section class="col-md-8 col-sm-8 pay-section float-left account-reg-section">
    <app-user-header
      [selectedHeader]="header"
      [tableCategory]="creditInvoice"
    ></app-user-header>

    <app-account-information
      *ngIf="header == 'accountInformation'"
      [formDetails]="formData"
    ></app-account-information>

    <!-- user info form starts  -->

    <app-user-information
      *ngIf="header == 'userInformation'"
      [formDetails]="formData"
    ></app-user-information>
    <!-- user info form ends -->
  </section>
</section>
