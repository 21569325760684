import { Injectable } from '@angular/core';
import { CsAgentAuthService } from '@spartacus/asm/root';
import { GlobalMessageService, GlobalMessageType, TranslationService } from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { BehaviorSubject, Observable } from 'rxjs';
import * as config from '../shared/config/app.config';
import { BaseService } from '../shared/services/base.service';
@Injectable({
  providedIn: 'root',
})
export class MakeAPaymentService {
  public creditInvoiceDate = new BehaviorSubject<any>(false); // behaviour subject containing the parent data of invoices & credit
  creditInvoiceDate$ = this.creditInvoiceDate.asObservable();
  public creditData = new BehaviorSubject<boolean>(false); // behaviour subject containing the credit Data
  creditData$ = this.creditData.asObservable();
  public invoicesData = new BehaviorSubject<boolean>(false); // behaviour subject containing the invoice Date
  invoicesData$ = this.invoicesData.asObservable();
  public closedInvoiceData = new BehaviorSubject<any>(false)
  closedInvoiceData$ = this.closedInvoiceData.asObservable()
  emails: any;
  emails$: any;

  constructor(
    private readonly baseService: BaseService,
    private readonly csAgentAuthService: CsAgentAuthService,
    private readonly user: UserAccountFacade,
    private globalMessage: GlobalMessageService,
    private readonly translation: TranslationService,
  ) {
  }

  public getInvoices(params): void {
    this.csAgentAuthService.isCustomerEmulated().subscribe((res) => {
      if (res) {
        this.user.get().subscribe((resp: any) => {
          const userId = resp?.userName;
          if (userId) {
            this.baseService.get('/users/'+userId+config.GET_INVOICES, params).subscribe((invoices) => {
              this.creditInvoiceDate.next(invoices);
            });
          }
        });
      } else {
        this.baseService.get(config.GET_INVOICES, params).subscribe((resp) => {
          this.creditInvoiceDate.next(resp);
        });
      }
    });
  }



  public getClosedInvoices(params): void {
    this.csAgentAuthService.isCustomerEmulated().subscribe((res) => {
      if (res) {
        this.user.get().subscribe((resp: any) => {
          const userId = resp?.userName;
          if (userId) {
            params.emulatedUser = userId;
            this.baseService.get(config.GET_CLOSEDINVOICES, params).subscribe((invoices)=>{
              this.closedInvoiceData.next(invoices)
            });
          }
        });
      } else {
        this.baseService.get(config.GET_CLOSEDINVOICES, params).subscribe((resp)=>{
          this.closedInvoiceData.next(resp)
        });
      }
    });
  }

  public downloadPdf(params): void {
    this.baseService
      .getPdf(config.DOWNLOAD_INVOICE_CREDIT_PDF, { invoiceNumber: params })
      .subscribe((res) => {
        const file = new Blob([res], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      });
  }

  public downloadPdfWithSystemId(params, systemId): void {
    this.baseService
      .getPdf(config.DOWNLOAD_INVOICE_CREDIT_PDF, {invoiceNumber: params, systemId: systemId})
      .subscribe((res) => {
          const file = new Blob([res], {type: 'application/pdf'});
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        },
        () => {
          this.translation
            .translate('httpHandlers.forbidden')
            .subscribe((res) => {
              this.globalMessage.remove(GlobalMessageType.MSG_TYPE_ERROR);
              this.globalMessage.add(res, GlobalMessageType.MSG_TYPE_ERROR);
            });
        });
  }

  public getCardDetails(params): any {
    return this.baseService.get(config.GET_CARD_DETAILS, params);
  }
  public saveCreditDetails(userName, params) {
    return this.baseService.patch(
      `${config.USER_ACCOUNT}${userName}/payment-confirmation-credit`,
      params
    );
  }

  public makeCreditCardPayment(userName, params) {
    return this.baseService.post(
      `${config.USER_ACCOUNT}${userName}/ccinvoice-payment`,
      params
    );
  }

  public getAccountId(userName): Observable<any> {
    return this.baseService.get(`${config.USER_ACCOUNT}${userName}/b2bUnits`);
  }
  getEmails(userId, b2bUnitID): void {
    this.emails = new BehaviorSubject<any>(false);
    this.emails$ = this.emails.asObservable();
    this.baseService
      .get(
        `${config.USER_ACCOUNT}${userId}/customerEmails?b2bUnitId=${b2bUnitID}`
      )
      .subscribe((res) => {
        if (res) {
          this.emails.next(res);
        }
      });
  }
}
